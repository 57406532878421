<template>
	<div>
		<div class="footer-top">
			<div class="wrapper">
				<div class="footer_text-2">
					Нажимая кнопку «Получить доступ», вы подтверждаете заказ услуги «Доступ к «Спортивному порталу Tele2» на условиях <a href="https://s3.tele2.ru/ds-site/docs/terms/Sports_portal.pdf">пользовательского соглашения</a>. Провайдер ООО "ФМК". Стоимость: 10.00 руб./сут. (с НДС). Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого счета Абонента.
				</div>
			</div>
		</div>
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">18+ © Tele2 Россия, {{ year() }}</div>
					<div class="footer_text-3">Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie в соответствии с <a href="https://tele2.ru/api/media/content?contentId=m3100004">Политикой Компаний Группы Tele2</a> в области использования файлов cookie</div>
					<a href="https://s3.tele2.ru/ds-site/docs/terms/Sports_portal.pdf" target="_blank" class="footer_link">Условия сервиса</a>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		}
	}
</script>
