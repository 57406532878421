<template>
	<div class="sport-rec">
		<div v-if="sport === 'soccer'">
			<iframe src='https://sportrecs.com/redirect/embed/G2FxxWll-G2KI4Uz-b9r8Q/G8zCtILHw6YgoP9dm7lSgA' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>
		</div>
		<div v-else-if="sport === 'basketball'">
			<iframe src='https://sportrecs.com/redirect/embed/tKHKM2yhIwtH-lXv-Yk_5g/G8zCtILHw6YgoP9dm7lSgA' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>
		</div>
		<div v-else-if="sport === 'hockey'">
			<iframe src='https://sportrecs.com/redirect/embed/7YxHWiTCtMycGYwcOFZkPg/G8zCtILHw6YgoP9dm7lSgA' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>
		</div>
		<div v-else-if="sport === 'mma'">
			<iframe src='https://sportrecs.com/redirect/embed/cqPP_idO8vciHcKx9o1Z8A/G8zCtILHw6YgoP9dm7lSgA' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['sport']
	}
</script>

<style scoped>

</style>
