import middleware from "../../middleware/middleware";

class Route {
	routes = [];
	route = {};

	set(path) {
		this.routes.push({path});
		
		this.route = this.routes[this.routes.length - 1];
		
		return this;
	}
	name(name) {
		this.route.name = name;
		
		return this;
	}
	component(component) {
		this.route.component = component;
		
		return this;
	}
	redirect(redirect) {
		this.route.redirect = redirect;
		
		return this;
	}
	withProject(name) {
		this.route.projectName = name;
		
		return this;
	}
	middleware(name) {
		middleware[name](this.route);
		
		return this;
	}
	getRoutes() {
		let newRoutes = {};
		
		this.routes.forEach((item) => {
			let currentRoutePage = this.routes.find(x => x.projectName === window.projectName && x.name === item.name);
			let defaultRoutePage = this.routes.find(x => x.projectName === window.projects.Default && x.name === item.name);
			
			newRoutes[item.name] = currentRoutePage ? currentRoutePage : defaultRoutePage;
		});
		
		return Object.values(newRoutes);
	}
}

export default new Route();
