<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События {{ activeDay.defaultValue }}</div>
			
			<div class="match_row">
				<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
				
				<div class="match_left" v-else>
					<div class="info-text"></div>
				</div>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<div class="loading" v-if="loading" style="padding-top: 20px;"></div>
						<list v-else :matches="allMatches" :finishedMatches="allFinishedMatches" :liveMatches="allLiveMatches"></list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	
	import Leagues from "@/components/Sports/Common/Leagues/Leagues";
	import MatchHeader from '@/components/Sports/Common/MatchHeader/MatchHeader';
	import List from "@/components/Sports/Hockey/Events/List/List";

	export default {
		components: {
			Leagues,
			MatchHeader,
			List
		},

		data() {
			return {
				sport: this.$route.params.sport,
				activeDay: {},
				loading: true
			}
		},

		computed: {
			...mapGetters({
				leagues: 'hockey/leagues',
				allMatches: 'hockey/allMatches',
				allFinishedMatches: 'hockey/allFinishedMatches',
				allLiveMatches: 'hockey/allLiveMatches',
			}),
		},

		created() {
			this.fetchLeagues();

			this.update();
		},

		methods: {
			...mapActions({
				fetchLeagues: 'hockey/fetchLeagues',
				fetchAllMatches: 'hockey/fetchAllMatches',
			}),
			
			
			initDay(day) {
				this.activeDay = day;
			},
			initData() {
				let day = this.$route.query.day_diff || 0;

				this.fetchAllMatches({
					params: {day_diff: day},
					callback: () => {
						this.loading = false;
					}
				});
			},
			update() {
				if (this.sport !== this.$route.params.sport) return;

				this.initData();

				setTimeout(() => {
					this.update();
				}, 1000 * 60);
			}
		}
	}
</script>

<style scoped>

</style>
