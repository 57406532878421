<template>
	<div class="match_items">
		<div class="match_item" v-for="(standingGroup, index) in standings" :key="standingGroup.name + ' ' + index">
			<div class="match_item-content">
				<div class="match_item-header active">
					<span>{{ standingGroup.name }}</span>
					<i class="ico icon-side-arrow"></i>
				</div>
				<div class="match_item-data active">
					<div class="live-table_content">
						<div class="live-table_row">
							<div class="live-table_col __1"></div>
							<div class="live-table_col __2">
								<div class="live-table_name">Команда</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">И</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">В</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">Н</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">П</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">Г</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">О</div>
							</div>
						</div>
						<div class="live-table_data">
							<div class="live-table_row" v-for="standing in standingGroup.rows" :key="'standing' + standing.id">
								<span class="live-table_row-after" :style="'background: ' + getColor(standing)"></span>
								
								<div class="live-table_col __1">
									<div class="live-table_num ">{{ standing.pos }}</div>
								</div>
								<div class="live-table_col __2">
									<div class="live-table_main">
										<router-link :to="{ name: 'team', params: {id: standing.id} }" class="live-table_name-main" href="">
											{{ standing.name }}
										</router-link>
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ standing.overall.gp }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing.overall.w }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing.overall.d }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing.overall.l }}
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ standing.overall.gs }}
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ standing.total.p }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],

		computed: {
			standings() {
				return this.data;
			}
		},
		methods: {
			getColor(standing) {
				let colors = {
					'Relegation Round': '#bd0000',
					'Championship Round': '#004682',
					'8th Finals': '#004682',
					'16th Finals': '#004682',
					'UEFA Europa League': '#3fcbff',
					'Promotion - Champions League (Group Stage)': '#004682',
					'Promotion - Champions League (Qualification)': '#3fcbff',
					'Promotion - Europa League (Group Stage)': '#3fcbff',
					'Promotion - Europa Conference League (Qualification)': '#ff641e',
					'Promotion - Europa League (Qualification)': '#ff641e',
					'Relegation - Championship': '#bd0000',
					'Bundesliga (Relegation)': '#bd0000',
					'Relegation - 2. Bundesliga': '#ff4141',
					'Relegation - LaLiga2': '#bd0000',
					'Relegation - Serie B': '#bd0000',
					'Premier League (Relegation)': '#bd0000',
					'Relegation - FNL': '#bd0000',
					'Ligue 1 (Relegation)': '#ff4141',
					'Relegation - Ligue 2': '#bd0000',
					'CONMEBOL Libertadores': '#004682',
					'CONMEBOL Libertadores Qualifiers': '#3fcbff',
					'CONMEBOL Sudamericana': '#ff641e',
					'Final Series': '#004682',
					'Final Series Play-offs': '#3fcbff',
				};

				return colors[standing.description] ? colors[standing.description] : '';
			}
		}
	}
</script>

<style scoped>

</style>
