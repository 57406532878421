<template>
	<div class="videos">
		<div class="wrapper">
			<h1 class="videos_title">Видео</h1>
			
			<video-categories @update="updateCategory" :category="category"></video-categories>
			
			<div class="videos_row">
				<div class="videos_col" v-for="item in items" :key="item.id">
					<div class="video-block_item __full">
						<router-link :to="{name: 'video', params: {id: item.id}}" class="video-block_image">
							<img :src="$hostname + item.image_ref" alt="">
						</router-link>
						
						<router-link :to="{name: 'video', params: {id: item.id}}" class="video-block_title">
							{{ item.title }}
						</router-link>
					</div>
				</div>
			</div>
			
			<paginate v-if="count" @page-changed="updateData" :total="count" :limit="limit"></paginate>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import VideoCategories from "../../components/Videos/VideoCategories";
	import Paginate from "../../components/Pagination/Paginate";

	export default {
		components: {
			VideoCategories,
			Paginate
		},

		data() {
			return {
				category: this.$route.query.category_id,
				page: Number(this.$route.query.page) || 1,
				items: [],
				limit: 8,
				count: 0,
				gotData: false
			}
		},

		created() {
			this.initVideos();
		},

		methods: {
			initVideos() {
				let params = {
					category_id: this.category,
					limit: this.limit,
					page: this.page
				};

				this.axios.get('/api/videos', {params: params})
				.then(({data}) => {
					this.items = data.data;
					this.count = data.count;
					this.gotData = true;
				});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},

			updateData(page) {
				this.page = page;

				this.$router.push({
					name: 'videos',
					query: {
						page: page,
						category_id: this.category
					}
				});
			},
			updateCategory(category) {
				this.category = category;

				this.updateData(1);
			}
		}
	}
</script>

<style scoped>

</style>
