<template>
	<div>
		<div class="content-block" v-if="player">
			<div class="wrapper">
				<div class="player-card">
					<h2 class="player-card_title">{{ player.name }}</h2>
					<div class="player-card_main">
						<div class="player-card_image">
							<img :src="'data:image/png;base64, ' + player.image" alt="">
						</div>
						<div class="player-card_content">
							<h3>Досье</h3>
							
							<div class="player-card_row">
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Имя</td>
												<td>
													<strong>{{ player.name }}</strong>
												</td>
											</tr>
											<tr>
												<td>Дата рождения</td>
												<td>
													<strong>{{ player.bday }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Страна</td>
												<td>
													<strong>{{ player.country }}</strong>
												</td>
											</tr>
											<tr>
												<td>Ранк</td>
												<td>
													<strong>{{ player.rank }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<br>
				
				<tab :headings="{results: 'Результаты'}" active-key="results">
					<template v-slot:results>
						<links-results :data="player.results.category"></links-results>
					</template>
				</tab>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	
	import Tab from "@/components/Tab/Tab";
	import LinksResults from "@/components/Sports/Tennis/Events/LinksResults/LinksResults";

	export default {
		components: {
			Tab,
			LinksResults
		},

		data() {
			return {
				id: this.$route.params.id,
				loading: true
			}
		},

		computed: {
			...mapGetters({
				player: 'tennis_player/player'
			})
		},

		created() {
			this.fetchPlayer({id: this.$route.params.id});
		},

		methods: {
			...mapActions({
				fetchPlayer: 'tennis_player/fetchPlayer'
			})
		},
	}
</script>

<style scoped>

</style>
