import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

let locale = localStorage.getItem('locale') || 'ru';

const i18n = new VueI18n({
	locale: locale,
	fallbackLocale: 'ru',

	messages: {
		ru: require('./../locale/ru.json')
	}
});

export default i18n;
