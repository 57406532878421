<template>
	<div class="news">
		<div class="wrapper">
			<div class="news_content __item">
				<h1 class="news_title __left">
					{{ news.title }}
				</h1>
				<p>
					{{ getTime(news.pub_date) }}
				</p>
				<div v-html="news.content"></div>
			</div>
			<br>
			
			<sport-rec-index :sport="sport"></sport-rec-index>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import SportRecIndex from "../../components/SportRecIndex/SportRecIndex";

	export default {
		components: {
			SportRecIndex
		},
		
		data() {
			return {
				news: {},
				sport: this.$route.params.sport
			}
		},

		created() {
			this.axios.get('/api/news/' + this.$route.params.id)
			.then(({data}) => {
				this.news = data;
			});
		},

		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm')
			}
		}
	}
</script>

<style scoped>

</style>
