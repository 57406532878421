// Projects name
window.projects = {
	FMK: 'FMK',
	Zet: 'Zet',
	Tcell: 'Tcell',
	Babilon: 'Babilon',
	Default: 'Default',
};


// Current project
switch (location.hostname) {
	case 'sport-tcell.roscontent.ru':
	case 'sport.tcell.tj':
		window.projectName = window.projects.Tcell;
		break;

	default:
		window.projectName = window.projects.Default;
		break;
}

function includeScript(filename) {
	var head = document.getElementsByTagName('head')[0];

	var script = document.createElement('script');
	script.src = filename;
	script.type = 'text/javascript';

	head.appendChild(script)
}
