<template>
    <div class="landing" @click="goToPage">
        <img src="/img/landing.jpg" alt="">
        <br>
        <a href="" class="btn __black">Смотреть</a>
    </div>
</template>
<script>
export default {
    methods: {
        goToPage() {
            location.href = 'https://sport.tele2.ru/';
        }
    }
}
</script>
<style scoped>
    .landing {
        padding: 20px;
        text-align: center;
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 99999;
    }
    .landing img {
        margin-bottom: 20px;
    }
</style>
