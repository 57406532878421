<template>
	<div v-if="data && data.length">
		<div class="header-block">
			<h2 class="header-block_title">Статьи</h2>
		</div>
		
		<div class="news-block">
			<div>
				<div class="news-block_item __not-first"  v-for="article in data" :key="article.id">
					<router-link :to="{ name: 'article', params: { id: article.id, sport: 'soccer' } }" class="news-block_image">
						<img :src="article.image_ref" alt="">
						<div class="main-news-2_partner-logo"></div>
					</router-link>
					
					<div class="news-block_content">
						<div class="news-block_time __top">{{ getNewsTime(article.time) }}</div>
						
						<router-link :to="{ name: 'article', params: { id: article.id } }" class="news-block_title">
							{{ article.title}}
						</router-link>
						
						<div class="news-block_time">
							<span>{{ getNewsTime(article.time) }}</span>
							
							<router-link :to="{ name: 'article', params: { id: article.id } }">Подробнее</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['sport', 'data'],

		data() {
			return {
				articles: null,
				options: {
					arrows: false,
					dots: true
				}
			}
		},
		computed: {
			articlesLink() {
				return '/articles?page=1&sport=hockey/articles';
			}
		},

		methods: {
			getNewsTime(time) {
				return moment(time).locale("ru").format('D MMMM Y, HH:mm')
			},
		}
	}
</script>

<style scoped>

</style>
