import helpers from "../../../../helpers";

export default {
	// Fetch matches
	async fetchMatches(ctx, params) {
		let matches = await axios.get('/goalserve/tennis/league/events', { params });

		return matches.data.res;
	},

	// Fetch all existed matches
	async fetchAllMatches({ state, dispatch }, params) {
		let matches = await dispatch('fetchMatches', params.params);

		state.allMatches = matches;

		params.callback();
	},

	// Fetch all leagues
	async fetchLeagues({ state }) {
		let leagues = await axios.get('/goalserve/tennis/league');

		state.leagues = leagues.data;
	},
	// Fetch all leagues
	async fetchRankList({ state }, params) {
		let rankList = await axios.get('/goalserve/tennis/scores/' + params.params.id);

		state.rankList = rankList.data.res;
		
		params.callback();
	},
	updatePage({state}) {
		state.rankListPage++;
	}
}
