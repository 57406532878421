<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_date">
								{{ getDateTime(match.date, match.time) }}
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team-hockey', params: {id: match.home_id, sport: sport, league: league}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.home_id" alt="">
									<span>{{ match.home_name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ match.home_score }} -&nbsp; {{ match.away_score }}
									</div>
									<div class="live-command_res">
										<div v-if="match.status === 'Finished'">ЗАВЕРШЕН</div>
										<div v-else-if="match.status === 'Not Started'">МАТЧ НЕ НАЧАЛСЯ</div>
										<div v-else-if="match.status === 'Cancl.'">ОТМЕНЕН</div>
										<div v-else-if="match.status === 'Postp.'">ОТЛОЖЕН</div>
										<div v-else-if="match.status">{{ getStatus(match.status) }}</div>
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: match.away_id}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.away_id" alt="">
									<span>{{ match.away_name }}</span>
								</router-link>
							</div>
						</div>
						
						<tab :headings="{timeline: 'Обзор',  h2h: 'Последние матчи'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline v-if="match" :match="match" :sport="sport"></timeline>
							</template>
							<template v-slot:stat>
								<statistic v-if="match.stats" :data="match.stats"></statistic>
							</template>
							<template v-slot:lineups>
								<lineups :match="match" v-if="match.lineups"></lineups>
							</template>
							<template v-slot:h2h>
								<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
								
								<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	import Tab from "@/components/Tab/Tab";
	import Statistic from "@/components/Sports/Hockey/Match/Statistic/Statistic";
	import Lineups from "@/components/Sports/Hockey/Match/Lineups/Lineups";
	import Timeline from "@/components/Sports/Hockey/Match/Timeline/Timeline";
	import LastResults from "@/components/Sports/Hockey/Match/LastResults/LastResults";
	import TotalResults from "@/components/Sports/Hockey/Match/TotalResults/TotalResults";

	export default {
		components: {
			Tab,
			Statistic,
			Lineups,
			Timeline,
			LastResults,
			TotalResults
		},

		data() {
			return {
				match: null,
				sport: this.$route.params.sport,
				league: this.$route.params.league,
				day_diff: this.$route.query.day_diff,
				h2h: null,
				loading: true,
			}
		},

		created() {
			let id = this.$route.params.id;

			this.axios.get('/goalserve/hockey/league/events?static_id=' + id + '&day_diff=' + this.day_diff + '&league_id=' + this.league)
			.then(({data}) => {
				this.match = data.res ? data.res[0].events[0] : null;
				this.loading = false;

				this.axios.get('/goalserve/hockey/h2h?team1=' + this.match.home_id + '&team2=' + this.match.away_id)
				.then(({data}) => {
					this.h2h = data.res;
				});
			});
		},
		methods: {
			getDateTime(date, time) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				return moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours').format('DD.MM.Y HH:mm');
			},
			getStatus(status) {
				let data = {
					'1st Period': '1-й период',
					'2nd Period': '2-й период',
					'3rd Period': '3-й период',
					'Finished': 'Завершен',
					'Break Time': 'Перерыв',
					'Cancelled': 'Отменен',
					'After Penalties': 'После буллитов',
					'After Over Time': 'После ОТ',
					'Postponed': 'Перенесен',
					'Not Started': 'Не нач.',
					'Awarded': 'Тех. Поражение'
				};

				return data[status] ? data[status] : status;
			}
		}
	}
</script>
