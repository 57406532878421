<template>
	<!-- eslint-disable -->
	
	<div>
		<div class="match_items">
			<div class="match_item">
				<div class="match_item-content" v-for="schedule in pagedSchedules">
					<div>
						<div class="match_item-header active">
							<span v-text="schedule.value"></span>
							<i class="ico icon-side-arrow"></i>
						</div>
						<div class="match_item-data active">
							<div class="match-data">
								<div v-for="match in schedule.event" class="match-data_item">
									<div class="match-data_row">
										<div class="match-data_col-1">{{ match.time }} </div>
										
										<div class="match-data_col-7 __type-2">{{ match.name }} <span>- {{ match.sport }}</span></div>
										
										<div class="match-data_col-7 __type-3">{{ match.sport }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<button v-if="hide" class="olympic_button" @click="showAll()">Показать все</button>
	</div>
</template>

<script>
	export default {
		props: ['data'],

		data() {
			return {
				hide: true
			}
		},
		
		computed: {
			schedules() {
				return this.data.filter((value) => {
					return Array.isArray(value.event);
				})
			},

			pagedSchedules() {
				return this.hide ? this.schedules.slice(0, 3) : this.schedules;
			},
		},
		methods: {
			showAll() {
				this.hide = false;
			}
		}
	}
</script>

<style scoped>

</style>
