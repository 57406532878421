<template>
	<div>
		<!-- substitution match -->
		<div v-if="incident.substitution">
			<router-link :to="getPlayerLink(incident.on_id)" class="timeline_name __color-1">{{ incident.on }}</router-link>
			<router-link :to="getPlayerLink(incident.off_id)" class="timeline_name __color-2">{{ incident.off }}</router-link>
		</div>
		<!-- substitution event -->
		<div v-else-if="incident.type === 'subst'">
			<router-link :to="getPlayerLink(incident.playerId)" class="timeline_name __color-1">{{ incident.player }}</router-link>
			<router-link v-if="isNaN(incident.assist)" :to="getPlayerLink(incident.assistid)" class="timeline_name __color-2">
				{{ incident.assist }}
			</router-link>
		</div>
		
		<div v-else>
			<!-- VAR -->
			<div v-if="incident.type === 'var'">
				<div>Проверка VAR</div>
			</div>
			
			<div v-else>
				<!-- player name match -->
				<router-link :to="getPlayerLink(incident.id)" v-if="incident.name">{{ incident.name }}</router-link>
				<!-- player name event -->
				<div v-else-if="incident.type === 'var'">Гол не засчитан - офсайд</div>
				<div v-else-if="incident.player">
					<router-link :to="getPlayerLink(incident.playerId)">
						<div :class="{__bold: incident.type === 'goal'}">
							<span style="margin-right: 8px;" v-if="incident.type === 'goal'">{{ getGoalsData(incident.result) }}</span> {{ getPlayerName(incident.player) }}
						</div>
					</router-link>
				</div>
				
				<!-- assist match -->
				<router-link :to="getPlayerLink(incident.assist_id)" v-if="incident.assist_name">
					<span v-if="incident.type === 'goal' || incident.type === 'goals'">Передача:</span>
					({{ incident.assist_name }})
				</router-link>
				<!-- assist event goal -->
				<router-link :to="getPlayerLink(incident.assistid)" v-else-if="incident.assist && isNaN(incident.assist) && incident.type === 'goal'">
					<span v-if="incident.type === 'goal' || incident.type === 'goals'">Передача:</span>
					({{ getAssistName(incident.assist) }})
				</router-link>
				<div v-else-if="incident.comment">({{ getAssistName(incident.comment) }})</div>
				<div v-else-if="incident.type === 'var'"></div>
				<div v-else-if="incident.type === 'pen. miss'">({{ getAssistName(incident.type) }})</div>
				<!-- assist event (status, not assist name) -->
				<div v-else-if="incident.assist && isNaN(incident.assist)">({{ getAssistName(incident.assist) }})</div>
				<!-- statuses -->
				<div v-else-if="incident.type === 'goals' && incident.penalty === 'True'">(Пенальти)</div>
				<div v-else-if="incident.type === 'goals' && incident.owngoal === 'True'">(Автогол)</div>
				<div v-else-if="incident.type === 'pen miss'">(Незабитый пенальти)</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['team', 'data', 'allTimes'],

		created() {

		},

		computed: {
			incident() {
				return this.data;
			}
		},

		methods: {
			getAssistName(status) {
				let data = {
					'Tripping': 'Подножка',
					'Roughing': 'Грубая игра',
					'Handling': 'Игра рукой',
					'Holding': 'Задержка',
					'Foul': 'Фол',
					'Not on pitch, Unsportsmanlike conduct': 'Не на поле',
					'Not on pitch': 'Не на поле',
					'Unsportsmanlike conduct': 'Неспортивное поведение',
					'Delay of game': 'Задержка игры',
					'Pen.': 'Пенальти',
					'pen. miss': 'Незабитый пенальти',
					'Elbowing': 'Толчок',
					'Fighting': 'Драка',
					'Goal Disallowed - offside': 'Гол не засчитан - офсайд',
					'Time wasting': 'Затяжка времени',
					'Violent conduct': 'Неспортивное поведение',
					'Argument': 'Споры с судьей',
					'Professional handball': 'Игра рукой'
				};

				return data[status] ? data[status] : status;
			},
			getPlayerName(name) {
				let replace = {
					'(pen.)': '(Пенальти)',
					'(o.g.)': '(Автогол)'
				};

				for (let replaceItem in replace) {
					name = name.replace(replaceItem, replace[replaceItem]);
				}

				return name;
			},
			getPlayerLink(playerId) {
				return {
					name: 'player',
					params: {
						sport: 'soccer',
						id: playerId
					}
				}
			},
			getGoalsData(goals) {
				return goals.replace('[', '').replace(']', '');
			}
		}
	}
</script>

<style scoped>

</style>