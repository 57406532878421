<template>
	<div>
		<soccer-player v-if="sport === 'soccer'"></soccer-player>
		<tennis-player v-else-if="sport === 'tennis'"></tennis-player>
		<tennis-player v-else-if="sport === 'bsktbl'"></tennis-player>
	</div>
</template>

<script>
	import SoccerPlayer from "./Sports/Soccer/Soccer";
	import TennisPlayer from "./Sports/Tennis/Tennis";
	import BasketballPlayer from "./Sports/Basketball/Basketball";
	
	export default {
		components: {
			SoccerPlayer,
			TennisPlayer,
			BasketballPlayer
		},

		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>
