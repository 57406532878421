import Vue from 'vue';
import VueRouter from "vue-router";
import Route from "../index";
import auth from '../auth/auth'

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: Route.getRoutes()
});

router.beforeEach((to, from, next) => {
	auth(to, from, next);
});

export default router;
