import './settings';

import Vue from 'vue';
import store from './store';
import App from './App.vue'
import axios from "axios";
import router from './router/lib/router';
import i18n from "./plugins/i18n";
import { getTimeZoneNum } from './plugins/helpers';
const VueInputMask = require('vue-inputmask').default;

// Production Tip
Vue.config.productionTip = false;
Vue.config.local = location.hostname === 'localhost';

// Plugins initial
window.axios = Vue.prototype.axios = axios;
window.getTimeZoneNum = getTimeZoneNum;

// Base url
axios.defaults.baseURL = location.hostname === 'localhost' ? 'http://tele2.roscontent.ru' : '';

// Hostname
Vue.prototype.$hostname = location.hostname === 'localhost' ? 'http://tele2.roscontent.ru' : '';

// Events
window.events = new Vue();


// Vue initial
Vue.use(VueInputMask);

new Vue({
	render: h => h(App),
	router,
	store,
	i18n
}).$mount('#app')
