<template>
	<div id="datepicker"></div>
</template>

<script>
	export default {
		props: ['date'],
		
		data() {
			return {
				datepicker: null
			}
		},
		
		mounted() {
			var _this = this;
			
			this.datepicker = $('#datepicker').datepicker({
				onSelect (formattedDate, date, inst) {
					_this.$emit('dateSelected', date);
				},
			}).data('datepicker');
		},
	}
</script>

<style scoped>

</style>
