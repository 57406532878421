<template>
	<div class="match_menu tennis-leagues">
		<h2>Категории</h2>
		
		<div class="match_button" @click="menuActive = true">
			<span></span>
			<i data-v-9b8449ee="" class="ico icon-side-arrow"></i>
		</div>
		
		<div class="match_menu-inner" :class="{ active: menuActive }">
			<div class="header_menu-top match_menu-top">
				<div class="header_menu-close" @click="menuActive = false"></div>
			</div>
			<router-link :to="{ name: 'league', params: {id: league.type, sport: 'tennis'} }" class="match_menu-item" v-for="league in leagues" :key="league.name">
				<span class="league-name">{{ getTitle(league.name) }}</span>
			</router-link>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		props: ['data'],
		
		data() {
			return {
				menuActive: false,
				page: 1,
			}
		},
		
		created() {
			this.initTitle();
		},
		
		computed: {
			leagues() {
				return this.data.res;
			},
		},
		methods: {
			initTitle() {
				setTimeout(() => {
					var text = $('.tennis-leagues .match_menu-item.router-link-active span').text();
					
					if (text && text.length) {
						$('.tennis-leagues .match_button span').html('').append(text);
					} else {
						$('.tennis-leagues .match_button span').html('Категории');
					}
				}, 300);
			},
			
			getTitle(title) {
				let data = {
					'ATP Singles': 'ATP - Одиночный разряд',
					'WTA Sigles': 'WTA - Одиночный разряд',
					'ATP Dubles': 'ATP - Парный разряд',
					'WTA Dubles': 'WTA - Парный разряд'
				};
				
				return data[title];
			}
		},
	}
</script>
