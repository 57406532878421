<template>
	<div>
		<header-tcell></header-tcell>
		
		<div>
			<router-view :key="$route.fullPath"></router-view>
		</div>
		
		<footer-tcell></footer-tcell>
	</div>
</template>

<script>
	import HeaderTcell from "../Layout/Header/HeaderTcell";
	import FooterTcell from "../Layout/Footer/FooterTcell";

	export default {
		components: {
			HeaderTcell,
			FooterTcell
		}
	}
</script>

<style scoped>

</style>
