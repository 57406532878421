<template>
	<div>
		<tab :headings="{all: 'Все', live: 'LIVE', finished: 'Завершено'}" active-key="all" :base="true">
			<template v-slot:all>
				<links v-if="matches && matches.length" :data="matches"></links>
				<div class="info" v-else>Нет матчей</div>
			</template>
			<template v-slot:live>
				<links v-if="liveMatches && liveMatches.length" :data="liveMatches"></links>
				<div class="info" v-else>Нет матчей в прямом эфире</div>
			</template>
			<template v-slot:finished>
				<links v-if="allFinishedMatches && allFinishedMatches.length" :data="allFinishedMatches"></links>
				<div class="info" v-else>Нет завершенных матчей</div>
			</template>
			
			<template v-slot:custom-heading>
				<div class="tab_header-item">
					<a href="" @click.prevent="activeDateDropdown = !activeDateDropdown">Календарь</a>
					
					<div class="tab_header-dropdown" v-if="activeDateDropdown">
						<a :class="{active: day.key == activeDay.key}" @click.prevent="update(day.key)" href="" v-for="day in days" v-text="day.value" :key="day.key"></a>
					</div>
				</div>
			</template>
		</tab>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import Tab from "@/components/Tab/Tab";
	import Links from "../Links/Links";
	
	export default {
		components: {
			Tab,
			Links
		},
		
		props: ['data', 'matches', 'allFinishedMatches', 'liveMatches'],
		
		data() {
			return {
				day: this.$route.query.day_diff || 0,
				activeDateDropdown: false
			}
		},
		created() {
			events.$emit('activeDay', this.activeDay);
		},
		computed: {
			/*matches() {
				if (!this.data) return null;
				
				return this.data;
			},
			liveMatches() {
				if (!this.data) return null;

				var arr = JSON.parse(JSON.stringify(this.data));
				
				return arr.filter((league) => {
					league.events = league.events.filter((match) => {
						return match.status !== 'Finished' && match.status !== 'Not Started' && match.status != '1';
					});
					return league.events.length;
				});
			},*/
			/*finishedMatches() {
				if (!this.data) return null;

				var arr = JSON.parse(JSON.stringify(this.data));

				return arr.filter((league) => {
					league.events = league.events.filter((match) => {
						return match.status === 'Finished';
					});
					return league.events.length;
				});
			},*/
			days() {
				let data = [
					{
						key: '0',
						value: 'Сегодня',
						defaultValue: 'сегодня'
					}
				];
				
				for (let i = 1; i <= 7; i++) {
					data.unshift({
						key: '-' + i,
						value: moment().subtract(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().subtract(i, 'days').locale('ru').format('DD MMMM Y')
					});
					data.push({
						key: i,
						value: moment().add(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().add(i, 'days').locale('ru').format('DD MMMM Y')
					});
				}
				
				return data;
			},
			activeDay() {
				let day =  this.days.filter((day) => {
					return day.key == this.day;
				})[0];
				
				return day;
			}
		},
		methods: {
			update(day) {
				this.day = day;
				
				this.$router.push({
					name: 'live',
					params: {
						sport: 'tennis'
					},
					query: {
						day_diff: day
					}
				});
			}
		}
	}
</script>

<style scoped>

</style>
