<template>
	<div>
		<soccer-league v-if="sport === 'soccer'"></soccer-league>
		<hockey-league v-else-if="sport === 'hockey'"></hockey-league>
		<tennis-league v-else-if="sport === 'tennis'"></tennis-league>
		<basketball-league v-else-if="sport === 'bsktbl'"></basketball-league>
		<box-league v-else-if="sport === 'box'"></box-league>
	</div>
</template>

<script>
	import SoccerLeague from "./Sports/Soccer/Soccer";
	import TennisLeague from "./Sports/Tennis/Tennis";
	import HockeyLeague from "./Sports/Hockey/Hockey";
	import BasketballLeague from "./Sports/Basketball/Basketball";
	import BoxLeague from "./Sports/Box/Box";
	
	export default {
		components: {
			SoccerLeague,
			TennisLeague,
			HockeyLeague,
			BasketballLeague,
			BoxLeague
		},
		
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>
