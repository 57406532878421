<template>
	<div class="timeline">
		<div class="timeline_head" v-if="time5.length">Буллиты</div>
		<timeline-base :incidents="time5" v-if="time5.length"></timeline-base>
		<div class="timeline_head" v-if="time4.length">Овертайм</div>
		<timeline-base :incidents="time4" v-if="time4.length"></timeline-base>
		<div class="timeline_head" v-if="time3.length">3-й период</div>
		<timeline-base :incidents="time3" v-if="time3.length"></timeline-base>
		<div class="timeline_head" v-if="time2.length">2-й период</div>
		<timeline-base :incidents="time2" v-if="time2.length"></timeline-base>
		<div class="timeline_head" v-if="time1.length">1-й период</div>
		<timeline-base :incidents="time1" v-if="time1.length"></timeline-base>
	</div>
</template>

<script>
	import TimelineBase from "@/components/Sports/Hockey/Match/TimelineBase/TimelineBase";
	
	export default {
		components: {
			TimelineBase,
		},
		
		props: ['match', 'sport'],
		
		computed: {
			time1() {
				return this.match.game_events.filter((item) => {
					return item.period == 1;
				}).reverse()[0].events;
			},
			time2() {
				return this.match.game_events.filter((item) => {
					return item.period == 2;
				}).reverse()[0].events;
			},
			time3() {
				return this.match.game_events.filter((item) => {
					return item.period == 3;
				}).reverse()[0].events;
			},
			time4() {
				return this.match.game_events.filter((item) => {
					return item.period == 4;
				}).reverse()[0].events;
			},
			time5() {
				return this.match.game_events.filter((item) => {
					return item.period == 5;
				}).reverse()[0].events;
			},
		},
		
		created() {
			
		}
	}
</script>

<style scoped>

</style>
