<template>
	<div>
		<div class="res" v-if="windowWidth > 860">
			<div class="res_row">
				<div class="res_left">
					<!--<articles :articles="homeArticles"></articles>-->
					
					<div class="__pk">
					
					</div>
				</div>
				
				<div class="res_right">
					<div class="info-block">
						<div class="info-block_col">
						
						</div>
						<div class="info-block_col">
						
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="res" v-else>
		
		</div>
	</div>
</template>

<script>
	//import Articles from "../../components/Articles/SimpleArticles";
	import $ from 'jquery';
	import {mapActions, mapGetters} from 'vuex';

	export default {
		components: {
			//Articles
		},

		data() {
			return {
				windowWidth: $(window).width()
			}
		},
		computed: {
			...mapGetters({
				homeArticles: 'article/homeArticles'
			})
		},
		created() {
			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});

			this.fetchArticles();
		},
		methods: {
			/*...mapActions({
				fetchArticles: 'article/fetchHomeArticles'
			}),*/
		}
	}
</script>

<style scoped>

</style>
