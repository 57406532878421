<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_date">
								{{ match.time }}
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team', params: {id: home.id, sport: sport}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
									<span>{{ home.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ home.goals }} -&nbsp; {{ away.goals }}
									</div>
									<div class="live-command_res">
										<div v-if="match.status === 'Full-time'">ЗАВЕРШЕН</div>
										<div v-else-if="match.status === 'Not Started'">МАТЧ НЕ НАЧАЛСЯ</div>
										<div v-else-if="match.status === 'Cancl.'">ОТМЕНЕН</div>
										<div v-else-if="match.status === 'Postp.'">ОТЛОЖЕН</div>
										<div v-else-if="match.status">{{ match.status }}</div>
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: away.id}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
									<span>{{ away.name }}</span>
								</router-link>
							</div>
						</div>
						
						<tab :headings="{timeline: 'Обзор', h2h: 'Последние матчи'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline :match="match" :sport="sport"></timeline>
							</template>
							<template v-slot:h2h>
								<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
								
								<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информации пока нет, так как матч еще не начался.</div>
		</div>
	</div>
</template>

<script>
	import Tab from "../../../../components/Tab/Tab";
	import Timeline from "../../../../components/Sports/Basketball/Match/Timeline/Timeline";
	import LastResults from "../../../../components/Sports/Basketball/Match/LastResults/LastResults";
	import TotalResults from "../../../../components/Sports/Basketball/Match/TotalResults/TotalResults";

	export default {
		components: {
			Tab,
			Timeline,
			LastResults,
			TotalResults
		},

		data() {
			return {
				match: null,
				sport: this.$route.params.sport,
				h2h: null,
				loading: true
			}
		},

		created() {
			let id = this.$route.params.id;

			this.axios.get('/goalserve/bsktbl/league/events?id=' + id)
			.then(({data}) => {
				this.match = data.res ? data.res[0].events[0] : null;
				this.loading = false;

				if (!this.match) return;

				this.axios.get('/goalserve/bsktbl/h2h?team1=' + this.match.home_id + '&team2=' + this.match.away_id)
				.then(({data}) => {
					this.h2h = data.res;
				});
			});
		},

		computed: {
			home() {
				if (!this.match) {
					return null;
				}
				return this.match.home;
			},
			away() {
				if (!this.match) {
					return null;
				}
				return this.match.away;
			}
		},
	}
</script>
