<template>
	<div id="app" class="app" :class="'app_' + projectName">
		<app-fmk v-if="projectName === projects.FMK"></app-fmk>
		<app-zet v-else-if="projectName === projects.Zet"></app-zet>
		<app-tcell v-else-if="projectName === projects.Tcell"></app-tcell>
		<app-default v-else></app-default>
	</div>
</template>

<script>
	import $ from 'jquery';
	
	import AppDefault from "./components/App/App";
	import AppZet from "./components/App/AppZet";
	import AppFmk from "./components/App/AppFmk";
	import AppTcell from "./components/App/AppTcell";
	
	export default {
		name: 'App',
		
		components: {
			AppFmk,
			AppZet,
			AppTcell,
			AppDefault,
		},
		
		data() {
			return {
				projectName: window.projectName,
				projects: window.projects
			}
		},
		
		created() {
			$(document).on('click', '.match_item-header', function () {
				$(this).toggleClass('active');
				$(this).next().toggleClass('active');
			})
		}
	}
</script>

<style lang="less">
	@import "assets/less/main";
</style>
