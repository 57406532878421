<template>
	<div>
		<footer class="footer">
			<div class="footer_text">
				<div class="footer_age">18+</div>
				
				<span>© 2021, ООО «ФМК»</span>
			</div>
			
			<ul class="footer_menu">
				<li><router-link :to="{name: 'home'}" exact>Главная</router-link></li>
				<li><router-link :to="{name: 'live'}">Матч-центр</router-link></li>
				<li><router-link :to="{name: 'news'}">Новости</router-link></li>
				<li><router-link :to="{name: 'videos'}">Видео</router-link></li>
				<li><router-link :to="{name: 'tickets'}">Билеты и ТВ</router-link></li>
				<li><router-link :to="{name: 'academy'}">Академия</router-link></li>
				<li><router-link :to="{name: 'rank'}">Рейтинг</router-link></li>
				<li><router-link :class="{'router-link-active': $route.name in ['competition', 'transfers']}" :to="{name: 'competitions'}">Трансферы</router-link></li>
			</ul>
		</footer>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>

</style>