<template>
	<div>
		<div class="match">
			<div class="wrapper">
				<div class="match_row">
					<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
					
					<div class="match_right">
						<match-header></match-header>
						
						<tab :headings="{results: 'Результаты', schedule: 'Расписание', standings: 'Таблица'}" active-key="results">
							<template v-slot:results>
								<div class="loading" v-if="resultsLoading"></div>
								<links-result :data="leagueResults" :show-date="true"></links-result>
							</template>
							<template v-slot:schedule>
								<links-result :show-date="true" v-if="leagueSchedule" :data="leagueSchedule" :league-id="leagueId"></links-result>
							</template>
							<template v-slot:standings>
								<standings v-if="standings" :data="standings"></standings>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";
	
	import Tab from "@/components/Tab/Tab";
	import MatchHeader from "@/components/Sports/Common/MatchHeader/MatchHeader";
	import Leagues from "@/components/Sports/Common/Leagues/Leagues";
	import Standings from "@/components/Sports/Hockey/Events/Standings/Standings";
	import LinksResult from "@/components/Sports/Hockey/Events/LinksResult/LinksResult";
	

	export default {
		components: {
			MatchHeader,
			Leagues,
			Tab,
			Standings,
			LinksResult,
		},

		data() {
			return {
				leagueId: this.$route.params.id,
				sport: this.$route.params.sport,
				resultsLoading: true
			}
		},
		
		
		computed: {
			...mapGetters({
				leagues: 'hockey/leagues',
				leagueResults: 'hockey/leagueResults',
				leagueSchedule: 'hockey/leagueSchedule',
				standings: 'hockey/standings',
			}),
		},

		created() {
			this.fetchLeagues();
			this.fetchLeagueResults({
				params: {
					league_id: this.leagueId,
					status: 'finished'
				},
				callback: () => {
					this.resultsLoading = false;
				}
			});
			this.fetchLeagueSchedule({
				params: {
					league_id: this.leagueId,
					status: 'not_finished'
				},
			});
			this.fetchStandings({ leagueId: this.leagueId });
		},


		methods: {
			...mapActions({
				fetchLeagues: 'hockey/fetchLeagues',
				fetchLeagueResults: 'hockey/fetchLeagueResults',
				fetchLeagueSchedule: 'hockey/fetchLeagueSchedule',
				fetchStandings: 'hockey/fetchStandings',
			}),
		}
	}
</script>
