<template>
	<div class="match_left">
		<div class="match_menu">
			<div class="match_button" @click="menuActive = true">
				<span></span>
				<i data-v-9b8449ee="" class="ico icon-side-arrow"></i>
			</div>
			
			<div class="match_menu-inner" :class="{ active: menuActive }">
				<div class="header_menu-top match_menu-top">
					<div class="header_menu-close" @click="menuActive = false"></div>
				</div>
				<router-link :to="{ name: 'league', params: {id: league.id, sport: sport} }" class="match_menu-item" v-for="league in leagues" :key="league.id">
					<span class="match_menu-ico" v-if="league.country_code">
						<span class="flag-icon" :class="['flag-icon-' + getFlagName(league.country_code)]"></span>
					</span>
					<span class="league-name">{{ league.name }}</span>
				</router-link>
			</div>
			
			<button v-if="data.res.length > leagues.length" class="match_btn" @click="page++">Показать еще</button>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		props: ['sport', 'data'],

		data() {
			return {
				items: [],
				menuActive: false,
				page: 1,
				id: Number(this.$route.params.id),
			}
		},

		created() {
			this.initTitle();
		},

		computed: {
			leagues() {
				let data = this.data.res;

				if (this.sport && this.sport === 'soccer') {
					data = this.leaguesFiltered;
				} else {
					data = this.leaguesFilteredById;
				}

				if (window.innerWidth < 767) {
					return data;
				}

				return data.slice(0, this.page * 10);
			},
			leaguesFiltered() {
				let ids = [this.id, 1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221, 1428, 4529];

				ids = ids.reverse();
				let arr = this.data.res;

				ids.forEach((id) => {
					arr = arr.sort((a, b) => {
						if (a.id == id) {
							return -1;
						}
						if (b.id == id) {
							return 1;
						}
					});
				});

				return arr;
			},
			leaguesFilteredById() {
				let ids = [this.id];
				let arr = this.data.res;

				ids.forEach((id) => {
					arr = arr.sort((a, b) => {
						if (a.id == id) {
							return -1;
						}
						if (b.id == id) {
							return 1;
						}
					});
				});

				return arr;
			}
		},
		methods: {
			initTitle() {
				setTimeout(() => {
					var text = $('.match_menu-item.router-link-active').html();

					if (text && text.length) {
						$('.match_button span').html('').append(text);
					} else {
						$('.match_button span').html('Лиги');
					}
				}, 300);
			},
			getFlagName(flagName) {
				let exclude = {
					EN: 'gb-eng'
				};

				return exclude[flagName] ? exclude[flagName].toLowerCase() : flagName.toLowerCase();
			}
		},
	}
</script>
