<template>
	<div>
		<div class="subscribe-form">
			<div class="subscribe-form_ico">
				<img src="/img/ico-phone.svg" width="55" alt="">
			</div>
			<div class="subscribe-form_text">Чтобы продолжить использовать сервис без ограничений, необходимо оформить доступ
			</div>
			
			<a href="" @click.prevent="subscribe" class="btn __black">Подписаться</a>
			
			<div v-if="loading" class="subscribe-form_spinner">
				<img src="/img/spinner.gif" width="44" alt="">
			</div>
			
			<div class="subscribe-form_text-2">Стоимость 10 руб/день <br><br>или наберите <strong>*745</strong> со своего телефона</div>
			
			<div v-if="smsError" class="subscribe-form_error">«Услуга не была подключена. Проверьте SMS и подтвердите подключение подписки»</div>
			<div v-if="error" class="subscribe-form_error">
				Ошибка подписки. Попробуйте еще раз
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				subscribed: null,
				error: null,
				smsError: null,
				loading: false,
				login: null,
			}
		},

		created() {
			this.axios.get('/api/profile').then(({data}) => {
				this.login = data;
			});
		},

		methods: {
			subscribe() {
				if (this.loading) return;

				this.loading = true;
				this.error = false;
				this.smsError = false;
				this.subscribed = null;

				this.axios.get('/api/subscribe?action=activate');

				setInterval(() => {
					if (this.subscribed === 'subscribed') {
						location.reload();
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'wait_1355' || this.subscribed === 'wait_1715') {
						this.smsError = true;
						this.loading = false;
						return;
					}
					else if (this.subscribed === 'subscribe_error') {
						this.error = true;
						this.loading = false;
						return;
					}

					this.getSubscribedValue();
				}, 2000);
			},

			getSubscribedValue() {
				axios.get('/api/subscribe').then(({data}) => {
					this.subscribed = data.status;
				})
			}
		},
	}
</script>

<style scoped>

</style>
