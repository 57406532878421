<template>
	<div>
		<header-default></header-default>
		
		<div>
			<router-view :key="$route.fullPath"></router-view>
		</div>
		
		<footer-default></footer-default>
	</div>
</template>

<script>
	import HeaderDefault from "../Layout/Header/Header";
	import FooterDefault from "../Layout/Footer/Footer";
	
	export default {
		components: {
			HeaderDefault,
			FooterDefault
		},
    }
</script>

<style scoped>

</style>
