export default {
	namespaced: true,

	state: {
		games: []
	},
	actions: {
		async fetchGames({ state }) {
			let games = await axios.get('/api/games');

			state.games = games.data;
		},
	},
	getters: {
		games(state) {
			return state.games
		}
	}
}
