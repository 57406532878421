<template>
	<div class="match">
        <div v-if="loading" class="loading"></div>
        
		<div class="wrapper" v-else-if="match || matchEvent">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div v-if="matchEvent" class="live-command">
							<div class="live-command_date">
								{{ getDateTime(matchEvent.date, matchEvent.time) }}
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team', params: {id: matchEvent.home_id}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.home_id" alt="">
									</div>
									<span>{{ matchEvent.home_name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ matchEvent.home_score }} -&nbsp; {{ matchEvent.away_score }}
									</div>
									<div class="live-command_res">
										<div class="__red" v-if="matchEvent.timer">{{ matchEvent.timer }}'</div>
										<div class="__red" v-else-if="matchEvent.status === 'HT' || matchEvent.status === 'Half-time'">
											{{ $t('sports.soccer.match.status.HT') }}
										</div>
										<div v-else>{{ getStatus(matchEvent.status) }}</div>
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: matchEvent.away_id}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.away_id" alt="">
									</div>
									<span>{{ matchEvent.away_name }}</span>
								</router-link>
							</div>
						</div>
						<div v-else-if="match" class="live-command">
							<div class="live-command_date">
								{{ getDateTime(match.date, match.time) }}
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team', params: {id: home.id, sport: sport}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
									<span>{{ home.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ home.goals }} - {{ away.goals }}
									</div>
									<div class="live-command_res">
										<div>{{ $t('sports.soccer.match.status.' + match.status) }}</div>
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: away.id}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
									<span>{{ away.name }}</span>
								</router-link>
							</div>
						</div>
						
						<tab :headings="{timeline: 'Обзор', stat: 'Статистика', lineups: 'Составы', h2h: 'Последние матчи', text: 'Текстовая трансляция'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline v-if="matchEvent || match" :match="match" :matchEvent="matchEvent" :videos="videos"></timeline>
							</template>
							<template v-slot:stat>
								<statistic v-if="match && match.stats" :data="match.stats"></statistic>
								<div class="info" v-else>Нет данных</div>
							</template>
							<template v-slot:lineups>
								<lineups v-if="match && match.teams" :match="match"></lineups>
								<div class="info" v-else>Нет данных</div>
							</template>
							<template v-slot:text>
								<match-text v-if="matchEvent || match" :match="match" :matchEvent="matchEvent"></match-text>
								<div class="info" v-else>Нет данных</div>
							</template>
							<template v-slot:h2h>
								<div v-if="h2h">
									<total-results v-if="h2h.top50" :h2h="h2h" :match="match ? match : matchEvent"></total-results>
									
									<last-results v-if="h2h.last5_home" :match="match ? match : matchEvent" :results="h2h"></last-results>
								</div>
								<div class="info" v-else>{{ $t('common.nodata') }}</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">{{ $t('common.matchEmpty') }}</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import { mapGetters, mapActions } from 'vuex'
	
	import Tab from "@/components/Tab/Tab";
	import Statistic from "@/components/Sports/Soccer/Match/Statistic/Statistic";
	import Lineups from "@/components/Sports/Soccer/Match/Lineups/Lineups";
	import Timeline from "@/components/Sports/Soccer/Match/Timeline/Timeline";
	import LastResults from "@/components/Sports/Soccer/Match/LastResults/LastResults";
	import TotalResults from "@/components/Sports/Soccer/Match/TotalResults/TotalResults";
	import MatchText from "@/components/Sports/Soccer/Match/Text/Text";

	export default {
		components: {
			Tab,
			Statistic,
			Lineups,
			Timeline,
			LastResults,
			TotalResults,
			MatchText
		},

		data() {
			return {
				id: this.$route.params.id,
				leagueId: this.$route.params.league,
				sport: this.$route.params.sport,
				dayDiff: this.$route.query.day_diff,
				loading: true,
				videos: null,
			}
		},

		created() {
            this.loading = true;
            
			if (this.dayDiff || this.dayDiff == 0) {
				this.fetchMatchEvent({
					static_id: this.id,
					league_id: this.leagueId,
					day_diff: this.dayDiff,
					callback: () => {
						this.fetchH2h({
							home_id: this.matchEvent.home_id,
							away_id: this.matchEvent.away_id
						});

						this.fetchMatch({static_id: this.id, league_id: this.leagueId, day_diff: this.dayDiff});
						
						this.loading = false;
					}
				});
			} else {
				this.fetchMatch({
					static_id: this.id, 
					league_id: this.leagueId,
					day_diff: this.dayDiff,
					callback: () => {
						this.fetchH2h({
							home_id: this.match.localteam.id,
							away_id: this.match.visitorteam.id
						});
						
						this.loading = false;
					}
				});
			}
		},

		computed: {
			...mapGetters({
				match: 'soccer_game/match',
				matchEvent: 'soccer_game/matchEvent',
				h2h: 'soccer_game/h2h',
			}),
			
			home() {
				if (!this.match) {
					return null;
				}
				return this.match.localteam;
			},
			away() {
				if (!this.match) {
					return null;
				}
				return this.match.visitorteam;
			}
		},

		methods: {
			...mapActions({
				fetchMatchEvent: 'soccer_game/fetchMatchEvent',
				fetchMatch: 'soccer_game/fetchMatch',
				fetchH2h: 'soccer_game/fetchH2h',
			}),
			
			getDateTime(date, time) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				return moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours').format('DD.MM.Y HH:mm');
			},
			getDay() {
				let date = this.match.date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				return moment(date).diff(moment(), 'days');
			},
			getStatus(status) {
				let data = this.$t('sports.soccer.match.status');

				return data[status] ? data[status] : '';
			},
		}
	}
</script>
