<template>
	<div class="news" style="padding-bottom: 30px;">
		<div class="wrapper">
			<div class="main-news-2_title-row">
				<h1 class="main-news-2_title">Главные новости</h1>
			</div>
			
			<sport-categories @update="updateSport" :sport="sport"></sport-categories>
			
			<div class="main-news-2 __col-3">
				<div class="main-news-2_items" v-if="items.length">
					<div class="main-news-2_item" v-for="item in items" :key="item.id" style="margin-bottom: 15px;">
						<router-link :to="{ name: 'article', params: { id: item.id, sport: sport } }" class="main-news-2_image"
									 :style="'background-image: url(' + item.image_ref + ')'">
						</router-link>
						<router-link :to="{ name: 'article', params: { id: item.id, sport: sport } }">
							{{ item.title }}
						</router-link>
						<div class="news_bottom">
							<div class="news_info">
								{{ getTime(item.pub_date) }}
							</div>
						</div>
						<div class="main-news-2_partner-logo"></div>
					</div>
				</div>
				<div v-else>
					<div class="info-text" v-if="gotData">Статей нет</div>
					<div class="info-text" v-else style="padding-top: 20px;">Загружается...</div>
				</div>
			</div>
			
			<paginate v-if="count" @page-changed="updateData" :total="count" :limit="limit"></paginate>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import Paginate from "../../components/Pagination/Paginate";
	import SportCategories from "../../components/News/SportCategories";

	export default {
		components: {
			Paginate,
			SportCategories
		},

		data() {
			return {
				sport: this.$route.query.sport ? this.$route.query.sport : 'soccer',
				tag: this.$route.query.tag,
				page: Number(this.$route.query.page) || 1,
				items: [],
				limit: 9,
				count: 0,
				gotData: false
			}
		},

		mounted() {
			let _this = this;

            $('select').select2({
                minimumResultsForSearch: -1
            });
		},

		created() {
			this.initArticles();
		},


		methods: {
			initArticles() {
				let params = {
					sport: this.sport,
					limit: this.limit,
					page: this.page
				};

				if (this.tag) {
					params.tag = this.tag;
				}

				this.axios.get('/api/articles', {params: params})
					.then(({data}) => {
						this.items = data.data;
						this.count = data.count;
						this.gotData = true;
					});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},

			updateData(page) {
				this.page = page;

				this.$router.push({
					name: 'articles',
					query: {
						page: page,
						sport: this.sport
					}
				});
			},

			updateSport(sport) {
				this.sport = sport;

				this.updateData(1);
			}
		}
	}
</script>
