import helpers from "../../../../helpers";

export default {
	// Fetch matches
	async fetchMatches(ctx, params) {
		let matches = await axios.get('/goalserve/hockey/league/events', { params });

		return matches.data.res;
	},

	// Fetch all existed matches
	async fetchAllMatches({ state, dispatch }, params) {
		let matches = await dispatch('fetchMatches', params.params);

		state.allMatches = matches;

		params.callback();
	},

	// Fetch all leagues
	async fetchLeagues({ state }) {
		let leagues = await axios.get('/goalserve/hockey/league');

		state.leagues = leagues.data;
	},
	
	// Fetch leagues data
	async fetchLeaguesData({ commit, state, dispatch }, params) {
		let results = await axios.get('/goalserve/hockey/league/fixtures', { params });
		
		if (results.data.res) {
			results.data.res[0].events =  results.data.res[0].events.reverse();
			
			return results.data.res;
		}
		return [];
	},
	
	// Fetch all league results
	async fetchLeagueResults({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);
		
		state.leagueResults = results.reverse();
		
		params.callback();
	},
	
	// Fetch all league results 2
	async fetchLeagueResults2({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);
		
		state.leagueResults2 = results.reverse();
		
		params.callback();
	},
	
	// Fetch all league schedule
	async fetchLeagueSchedule({ commit, state, dispatch }, params) {
		state.leagueSchedule = await dispatch('fetchLeaguesData', params.params);
	},
	
	// Fetch all league schedule
	async fetchLeagueSchedule2({ commit, state, dispatch }, params) {
		state.leagueSchedule2 = await dispatch('fetchLeaguesData', params.params);
	},
	
	// Fetch team data
	async fetchTeam({ commit, state, dispatch }, params) {
		let team = await axios.get('/goalserve/soccer/team_info/' + params.params.id);

		state.team = team.data.res;

		params.callback();
	},

	// Fetch all team results
	async fetchTeamResults({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);

		state.teamResults.push(results);

		params.callback();
	},

	// Fetch all team results
	async fetchTeamSchedules({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);

		state.teamSchedule.push(results);

		params.callback();
	},
	
	// Fetch all standings
	async fetchStandings({ commit, state, dispatch }, params) {
		let standings = await axios.get('/goalserve/hockey/league/standings', { params: { league_id: params.leagueId } });
		
		state.standings = standings.data.res;
	},
}
