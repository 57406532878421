<template>
	<div v-if="data && data.length">
		<div class="header-block">
			<h2 class="header-block_title">Новости</h2>
		</div>
		
		<div class="last-news __full">
			<div class="last-news_row" v-for="newsItem in data" :key="newsItem.id">
				<router-link :to="{ name: 'news-item', params: {id: newsItem.id, sport: 'soccer'} }" class="last-news_title">
					{{ newsItem.title }}
				</router-link>
				
				<div class="last-news_date">
					{{ getTime(newsItem.pub_date) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['data'],

		computed: {
			newsLink() {
				return '/news?page=1&sport=hockey&tag=123';
			},
		},
		
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>
