import Vue from 'vue';
import Vuex from 'vuex';

import soccer from "./modules/match/soccer/index";
import soccer_game from "./modules/match_game/soccer/index";
import soccer_player from "./modules/player/soccer/index";
import tennis from "./modules/match/tennis/index";
import tennis_game from "./modules/match_game/tennis";
import tennis_player from "./modules/player/tennis/index";
import hockey from "./modules/match/hockey/index";
import news from "./modules/news/index";
import articles from "./modules/articles/index";
import videos from "./modules/videos/index";
import games from "./modules/games/index";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		soccer,
		soccer_game,
		soccer_player,
		
		tennis,
		tennis_game,
		tennis_player,

		hockey,
		
		news,
		articles,
		videos,
		games
	}
});
