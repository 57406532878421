export default {
	league1535Filter(league) {
		if (league.league_id == 1535) {
			return league.events.filter((item) => {
				return item.home_name === 'Россия' || item.away_name === 'Россия';
			});
		}

		return league.events;
	},
	filterByLeagues(matches) {
		let leagues = [1001, 1039, 1535, 1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221, 1428, 1363, 1051, 4529, 1056];

		return matches.filter((league) => {
			league.events = this.league1535Filter(league);

			return leagues.includes(parseInt(league.league_id)) && league.events.length
		});
	},
	sortData(data) {
		if (!data) {
			return null;
		}

		let leagues = [1039, 1457, 1531, 1603, 1005, 1007, 1204, 1229, 1399, 1269, 1221, 1428, 4529, 1051, 4529, 1056];

		leagues.reverse().forEach((id) => {
			data = data.sort((a, b) => {
				if (a.league_id == id) {
					return -1;
				}
				if (b.league_id == id) {
					return 1;
				}
			});
		});

		return data;
	},
};
