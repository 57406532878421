<template>
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content">
				<div>
					<div class="match_item-header active">
						<span v-text="matches.name"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in matches.match"
										 class="match-data_item"
										 :key="match.id"
										 :to="getLink(match)">
								<div class="match-data_row">
									<div class="match-data_col-1">{{ match.date }}</div>
									
									<div class="match-data_col-2" :class="{__bold: match.localteam.winner === 'True'}">
										<span v-text="match.localteam.name"></span>
									</div>
									
									<div class="match-data_col-4">-</div>
									
									<div class="match-data_col-6" :class="{__bold: match.awayteam.winner === 'True'}">
										<span v-text="match.awayteam.name"></span>
									</div>
									
									<div class="match-data_col-7" v-if="match.win_result">{{ getType(match.win_result.won_by.type) }}</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		computed: {
			matches() {
				return this.data;
			}
		},
		methods: {
			getLink(match) {
				return {
					name: 'event-no-league',
					params: {
						id: match.id
					}
				}
			},
			getType(type) {
				var obj = {
					'S Dec': 'Судейское решение',
					'Points': 'Судейское решение',
					'SUB': 'Добровольная сдача',
					'KO': 'Нокаут',
					'ТКО': 'Нокаут',
					'Guillotine Choke': 'Удушение Гильотиной',
					'Arm Triangle': 'Треугольник',
					'Rear Naked Choke': 'Удушение сзади',
					'Kimura': 'Кимура',
					'Punches': 'Удар в голову',
				};
				
				return obj[type] ? obj[type] : type;
			}
		}
	}
</script>

<style scoped>

</style>
