<template>
	<div>
		<div class="playing-form">
			<div class="playing-form_text">
				Тарификация интернет трафика оплачивается дополнительно по тарифам Оператора в соответствии с вашим тарифным планом.
			</div>
		</div>
		<div class="playing-form-layout" @click="hideForm"></div>
	</div>
</template>

<script>
	export default {
		methods: {
			hideForm() {
				this.$emit('hide');
			}
		}
	}
</script>
