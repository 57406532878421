import Home from "../pages/Home/Home";
import HomeFmk from "../pages/Home/HomeFmk";
import Live from "../pages/Live/Live";
import League from "../pages/League/League";
import TennisRank from "../pages/TennisRank/TennisRank";
import Match from "../pages/Match/Match";
import Player from "../pages/Player/Player";
import Team from "../pages/Team/Team";
import News from "../pages/News/News";
import NewsItem from "../pages/NewsItem/NewsItem";
import Articles from "../pages/Articles/Articles";
import Article from "../pages/Article/Article";
import Videos from "../pages/Videos/Videos";
import Video from "../pages/Video/Video";
import Games from "../pages/Games/Games";
import Tickets from "../pages/Tickets/Tickets";
import Subscribe from "../pages/Subscribe/Subscribe";
import SubscribeTcell from "../pages/Subscribe/SubscribeTcell";
import SubscribeZet from "../pages/Subscribe/SubscribeZet";
import NoMoney from "../pages/NoMoney/NoMoney";
import NotFound from "../pages/NotFound/NotFound";
import HockeyOlympics from "../pages/HockeyOlympics/HockeyOlympics";
import Landing from "../pages/Landing/Landing.vue";

export default {
	Home,
	HomeFmk,
	Live,
	League,
	TennisRank,
	Match,
	Player,
	Team,
	News,
	NewsItem,
	Articles,
	Article,
	Videos,
	Video,
	Games,
	Tickets,
	Subscribe,
	SubscribeTcell,
	SubscribeZet,
	NoMoney,
	NotFound,
	HockeyOlympics,
    Landing
};
