<template>
	<div class="content-block">
		<div class="wrapper">
			<div class="error-page">Страница не найдена</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style scoped>

</style>
