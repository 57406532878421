<template>
	<div>
		<div class="player">
			<div class="player_heading">
				<div class="player_row">
					<div class="player_col">#</div>
					<div class="player_col">Имя</div>
					<div class="player_col">Возраст</div>
					<div class="player_col">Игры</div>
					<div class="player_col">Голы</div>
					<div class="player_col">Ассисты</div>
					<div class="player_col">Минут</div>
				</div>
			</div>
		</div>
		
		<div v-for="(playersGroup, playersGroupName) in players" :key="playersGroupName">
			<div class="player_head">{{ getPositionName(playersGroupName) }}</div>
			<div class="player_body">
				<player v-for="player in playersGroup" :key="player.id" :player="player"></player>
			</div>
		</div>
		<div>
			<div class="player_head">Тренер</div>
			<div class="player_body">
				<player :player="team.coach"></player>
			</div>
		</div>
	</div>
</template>

<script>
	import Player from "../Player/Player";
	
	export default {
		components: {
			Player
		},
		
		props: ['team', 'players'],
		
		methods: {
			getPositionName(val) {
				if (val === 'G') {
					return 'Вратарь';
				} else if (val === 'D') {
					return 'Защитник';
				} else if (val === 'M') {
					return 'Полузащиник';
				} else if (val === 'A') {
					return 'Нападающий';
				}
			}
		}
	}
</script>

<style scoped>

</style>
