import Vue from "vue";

export default (to, from, next) => {
	if (Vue.config.local) {
		next(); return;
	}

	if (to.name === 'logout') {
		next(); return;
	}

	window.axios({url: '/a1/profile', baseURL: '/'}).then(({data}) => {
		if (to.name === 'home') {
			if (data.status === 'subscribed' || data.status === 'allowed') {
				window.events.$emit('subscribed');
				window.auth.subscribed = true;
			}
			next();return;
		}

		if(!data.msisdn) {
			if (to.name === 'home') {
				next(); return;
			} else {
				window.events.$emit('not-logged');
			}
		} else {
			window.events.$emit('auth');
			window.auth.auth = true;

			if (data.status === 'not_subscribed') {
				if (to.name === 'subscribe' || to.name === 'home') {
					next(); return;
				} else {
					location.href = '/subscribe';return;
				}
			} else if (data.status === 'subscribed' || data.status === 'allowed') {
				window.auth.subscribed = true;
				window.events.$emit('subscribed');

				if (to.name === 'subscribe') {
					location.href = '/'; return;
				} else {
					next(); return;
				}
			} else if (data.status === 'restricted') {
				if (to.name === 'restricted' || to.name === 'home') {
					next(); return;
				} else {
					location.href = '/restricted'; return;
				}
			}
		}

		if (to.name === 'home') {
			next();
		}
	});
}
