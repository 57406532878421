export default {
	match(state) {
		return state.match;
	},
	matchEvent(state) {
		return state.matchEvent;
	},
	h2h(state) {
		return state.h2h;
	},
}
