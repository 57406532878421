<template>
	<!-- eslint-disable -->
	
	<div v-if="data">
		<div class="match_items">
			<div class="match_item">
				<div class="match_item-content" v-for="result in pagedResults">
					<div v-if="showDate(result)">
						<div class="match_item-header active">
							<span v-text="result.value"></span>
							<i class="ico icon-side-arrow"></i>
						</div>
						<div class="match_item-data active">
							<div class="match-data">
								<div v-for="match in result.sport">
									<div v-if="match.name !== 'Ice Hockey' && match.name !== 'Curling'" class="match-data_res">
										<div class="match-data_res-data __1">{{ match.name }}</div>
										
										<div v-if="match.event.player">
											<div class="match-data_res-data __2" :class="{__mb0: !match.event.player}">{{ match.event.name }}</div>
											
											<div>
												<div v-for="player in match.event.player" class="match-data_row-2">
													<div class="match-data_col-8">{{ player.pos }}</div>
													<div class="match-data_col-9">{{ player.country }}</div>
													<div class="match-data_col-10">{{ player.name }}</div>
													<div class="match-data_col-11">{{ player.result }}</div>
												</div>
											</div>
										</div>
										
										<div v-else-if="Array.isArray(match.event)">
											<div v-for="event in match.event" class="match-data_res-arr">
												<div class="match-data_res-data __3">{{ event.name }}</div>
												
												<div>
													<div>
														<div v-for="player in event.player" class="match-data_row-2">
															<div class="match-data_col-8">{{ player.pos }}</div>
															<div class="match-data_col-9">{{ player.country }}</div>
															<div class="match-data_col-10">{{ player.name }}</div>
															<div class="match-data_col-11">{{ player.result }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<button v-if="hide" class="olympic_button" @click="showAll()">Показать все</button>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		data() {
			return {
				hide: true
			}
		},
		
		computed: {
			results() {
				if (!this.data) return null;
				
				return this.data.filter((item) => {
					return Array.isArray(item.sport)
				});
			},
			pagedResults() {
				return this.hide ? this.results.slice(0, 3) : this.results;
			}
		},
		
		methods: {
			showDate(result) {
				let sport = JSON.parse(JSON.stringify(result.sport));
				
				let hasData = sport.filter((element) => {
					return element.name !== 'Ice Hockey' && element.name !== 'Curling'
				})
				
				return hasData.length;
			},
			showAll() {
				this.hide = false;
			}
		}
	}
	
</script>

<style scoped>

</style>
