import { render, staticRenderFns } from "./Box.vue?vue&type=template&id=6a1aecd0&scoped=true&"
import script from "./Box.vue?vue&type=script&lang=js&"
export * from "./Box.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1aecd0",
  null
  
)

export default component.exports