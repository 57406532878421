<template>
	<div class="tab __pb __pt">
		<div class="wrapper">
			<div class="header-block_title">Матчи дня</div>
			
			<tab :headings="headings" :activeKey="activeKey" v-if="matchesLoaded">
				<template v-slot:yesterday>
					<links :data="yesterdayMatches" :day-diff="-1"></links>
				</template>
				
				<template v-slot:today>
					<links :data="todayMatches" :day-diff="0"></links>
				</template>
				
				<template v-slot:tomorrow>
					<links :data="tomorrowMatches" :day-diff="1"></links>
				</template>
				
				<template v-slot:live>
					<links :data="liveMatches"></links>
				</template>
			</tab>
			
			<div v-else class="loading"></div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";
	
	import Tab from "../../Tab/Tab";
	import Links from "../../Sports/Soccer/Events/Links/Links";
	
	export default {
		components: {
			Tab,
			Links
		},
		
		data() {
			return {
				
			}
		},

		computed: {
			...mapGetters({
				yesterdayMatches: 'soccer/yesterdayMatches',
				tomorrowMatches: 'soccer/tomorrowMatches',
				todayMatches: 'soccer/todayMatches',
				liveMatches: 'soccer/liveMatches'
			}),
			
			headings() {
				return {
					yesterday: 'Вчера (' + this.matchesLength(this.yesterdayMatches) + ')',
					today: 'Сегодня (' + this.matchesLength(this.todayMatches) + ')',
					tomorrow: 'Завтра (' + this.matchesLength(this.tomorrowMatches) + ')',
					live: 'LIVE (' + this.matchesLength(this.liveMatches) + ')',
				}
			},
			activeKey() {
				return this.todayMatches.length ? 'today' :
							this.tomorrowMatches.length ? 'tomorrow' : 
								this.yesterdayMatches.length ? 'yesterday' : 'today'
			},
			matchesLoaded() {
				return this.todayMatches && this.tomorrowMatches && this.yesterdayMatches;
			}
		},

		created() {
			this.fetchYesterdayMatches();
			this.fetchTomorrowMatches();
			this.fetchTodayMatches();
		},

		methods: {
			...mapActions({
				fetchYesterdayMatches: 'soccer/fetchYesterdayMatches',
				fetchTomorrowMatches: 'soccer/fetchTomorrowMatches',
				fetchTodayMatches: 'soccer/fetchTodayMatches',
			}),
			matchesLength(matches) {
				if (!matches) return 0;

				let count = 0;

				matches.forEach((data) => {
					count += data.events.length;
				});

				return count;
			}
		},
	}
</script>

<style scoped>

</style>
