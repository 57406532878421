export default {
	namespaced: true,

	state: {
		news: null
	},
	actions: {
		async fetchNews({ state }, params) {
			let news = await axios.get('/api/news', {params: params.params});

			state.news = news.data.data;
			
			if (params.callback) {
				params.callback();
			}
		},
	},
	getters: {
		news(state) {
			return state.news
		}
	}
}
