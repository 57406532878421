<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События {{ activeDay.defaultValue }}</div>
			
			<div class="match_row">
				<div class="match_left">
					<rank-list></rank-list>
					
					<br>
					
					<leagues v-if="leagues" :data="leagues"></leagues>
				</div>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<div class="rank-list">
							<div class="rank-list_row __head">
								<div class="rank-list_col __rank">#</div>
								<div class="rank-list_col __player">Имя</div>
								<div class="rank-list_col __country">Страна</div>
								<div class="rank-list_col __points">Очки</div>
							</div>
							<div class="loading" v-if="loading"></div>
							<div v-else>
								<div class="rank-list_row" v-for="rankItem in ranks" :key="'rank-' + rankItem.id">
									<div class="rank-list_col __rank">
										<span>{{ rankItem.rank }}</span>
										<span class="rank-list_up" v-if="rankItem.movement === 'up'"></span>
										<span class="rank-list_down" v-if="rankItem.movement === 'down'"></span>
									</div>
									<div class="rank-list_col __player">{{ rankItem.name }}</div>
									<div class="rank-list_col __country">{{ rankItem.country }}</div>
									<div class="rank-list_col __points">{{ rankItem.points }}</div>
								</div>
							</div>
						</div>
						
						<button v-if="ranks && rankList.length > ranks.length" class="match_btn" @click="updatePage">Показать еще</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import Leagues from "@/components/Sports/Tennis/Events/Leagues/Leagues"
	import MatchHeader from "@/components/Sports/Common/MatchHeader/MatchHeader"
	import List from "@/components/Sports/Tennis/Events/List/List";
	import RankList from "@/components/Sports/Tennis/Events/RankList/RankList";

	export default {
		components: {
			Leagues,
			MatchHeader,
			List,
			RankList
		},

		data() {
			return {
				id: this.$route.params.id,
				activeDay: {},
				loading: true,
			}
		},

		created() {
			this.fetchLeagues();
			
			this.fetchRankList({
				params: { id: this.id },
				callback: () => {
					this.loading = false;
				}
			});

			events.$on('activeDay', this.initDay);
		},

		computed: {
			...mapGetters({
				leagues: 'tennis/leagues',
				rankList: 'tennis/rankList',
				ranks: 'tennis/ranks',
			}),
		},

		methods: {
			...mapActions({
				fetchLeagues: 'tennis/fetchLeagues',
				fetchRankList: 'tennis/fetchRankList',
				updatePage: 'tennis/updatePage',
			}),

			
			initDay(day) {
				this.activeDay = day;
			},
		}
	}
</script>

<style scoped>

</style>
