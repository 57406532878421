import helpers from "../../../../helpers";

export default {
	yesterdayMatches(state) {
		return helpers.sortData(state.yesterdayMatches);
	},
	tomorrowMatches(state) {
		return helpers.sortData(state.tomorrowMatches);
	},
	todayMatches(state) {
		return helpers.sortData(state.todayMatches);
	},
	liveMatches(state) {
		if (!state.todayMatches) return [];

		let arr = JSON.parse(JSON.stringify(state.todayMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.timer;
			});
			return league.events.length;
		});
	},
	leagues(state) {
		return state.leagues;
	},
	allMatches(state) {
		return helpers.sortData(state.allMatches);
	},
	allFinishedMatches(state, getters) {
		if (!getters.allMatches) return null;

		let arr = JSON.parse(JSON.stringify(getters.allMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.status === 'FT' || match.status === 'Finished';
			});
			return league.events.length;
		});
	},
	allLiveMatches(state, getters) {
		if (!getters.allMatches) return null;

		let arr = JSON.parse(JSON.stringify(getters.allMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.timer;
			});
			return league.events.length;
		});
	},
	leagueResults(state) {
		return state.leagueResults;
	},
	leagueSchedule(state) {
		return state.leagueSchedule;
	},
	team(state) {
		return state.team;
	},
	teamResults(state) {
		let data = [];

		if (state.teamResults && state.teamResults.length) {
			data = JSON.parse(JSON.stringify(state.teamResults)).reverse();

			data.forEach((item, index) => {
				if (item && item.length) {
					item = item.reverse();
				}
			});
		}

		let newData = [
			{
				group_suffix: 'Результаты',
				events: []
			}
		];

		data.forEach((result, index) => {
			if (result) {
				result.forEach((item, index) => {
					if (item) {
						item.events.forEach((event) => {
							newData[0].events.push(event);
						});
					}
				});
			}
		});

		return newData;
	},
	teamSchedule(state) {
		let data = [];

		if (state.teamSchedule && state.teamSchedule.length) {
			data = JSON.parse(JSON.stringify(state.teamSchedule));
		}

		let newData = [
			{
				group_suffix: 'Календарь',
				events: []
			}
		];

		data.forEach((result, index) => {
			if (result) {
				result.forEach((item, index) => {
					if (item) {
						item.events.forEach((event) => {
							newData[0].events.push(event);
						});
					}
				});
			}
		});

		return newData;
	},
	standings(state) {
		return state.standings;
	},
	scorers(state) {
		return state.scorers;
	},
}
