<template>
	<div>
		<div class="timeline_row-base" :class="{__hidden: incident.minute === -1}" v-for="(incident, index) in incidents" :key="index">
			<div v-if="match && lastEmptyExtraMinMatchId === incident.eventid">
				<div class="timeline_row" v-if="period === 1 && match.matchinfo.time.addedTime_period1">
					<div class="timeline_center __center">
						<div class="timeline_extra">
							<span>+{{ match.matchinfo.time.addedTime_period1 }}</span>
						</div>
					</div>
				</div>
				<div class="timeline_row" v-else-if="period === 2 && match.matchinfo.time.addedTime_period2">
					<div class="timeline_center __center">
						<div class="timeline_extra">
							<span>+{{ match.matchinfo.time.addedTime_period2 }}</span>
						</div>
					</div>
				</div>
			</div>
			
			<div class="timeline_row">
				<div class="timeline_left">
					<div class="timeline_name" v-if="incident.team === 'localteam'">
						<timeline-data :data="incident" :allTimes="allTimes"></timeline-data>
					</div>
					<div class="timeline_time" v-if="incident.team === 'visitorteam'">{{ incident.minute }}<span v-if="incident.extra_min">+{{incident.extra_min}}</span>'</div>
				</div>
				<div class="timeline_center">
					<div class="timeline_cart __yellow" v-if="incident.type === 'yellowcards' || incident.type === 'yellowcard'"></div>
					<div class="timeline_cart __red" v-if="incident.type === 'redcards' || incident.type === 'redcard'"></div>
					<div class="timeline_cart __yellow __yellow-2" v-if="incident.type === 'yellowred'"></div>
					<div class="timeline_ball" v-else-if="incident.type === 'goals' || incident.type === 'goal'"></div>
					<div class="timeline_arrow __subst" v-else-if="incident.type === 'subst'"></div>
					<div class="timeline_warning" v-else-if="incident.type === 'pen miss' || incident.type === 'pen. miss'"></div>
					<div class="timeline_var" v-else-if="incident.type === 'var'"></div>
					<div class="timeline_var-item" v-else-if="incident.type === 'var_item'"></div>
				</div>
				<div class="timeline_right">
					<div class="timeline_name" v-if="incident.team === 'visitorteam'">
						<timeline-data :data="incident" :allTimes="allTimes"></timeline-data>
					</div>
					<div class="timeline_time" v-if="incident.team === 'localteam'">{{ incident.minute }}<span v-if="incident.extra_min">+{{incident.extra_min}}</span>'</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TimelineData from "@/components/Sports/Soccer/Match/TimelineData/TimelineData";

	export default {
		components: {
			TimelineData
		},

		props: ['match', 'incidents', 'allTimes', 'period'],

		computed: {
			lastEmptyExtraMinMatchId() {
				if (!this.incidents[0].eventid) {
					return null;
				}

				for (let i = 0; i < this.incidents.length; i++) {
					if (this.incidents[i].extra_min === '') {
						return this.incidents[i].eventid;
					}
				}

				return null;
			}
		}
	}
</script>
