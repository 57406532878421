export default {
	liveMatches(state) {
		if (!state.todayMatches) return null;

		var arr = JSON.parse(JSON.stringify(state.todayMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.status !== 'Finished' && match.status !== 'Not Started' && match.status != '1';
			});
			return league.events.length;
		});
	},
	leagues(state) {
		return state.leagues;
	},
	allMatches(state) {
		return state.allMatches;
	},
	allFinishedMatches(state, getters) {
		if (!getters.allMatches) return null;

		var arr = JSON.parse(JSON.stringify(getters.allMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.status === 'Finished';
			});
			return league.events.length;
		});
	},
	allLiveMatches(state, getters) {
		if (!getters.allMatches) return null;

		let arr = JSON.parse(JSON.stringify(getters.allMatches));

		return arr.filter((league) => {
			league.events = league.events.filter((match) => {
				return match.timer;
			});
			return league.events.length;
		});
	},
	rankList(state) {
		return state.rankList;
	},
	ranks(state) {
		if (!state.rankList) {
			return null;
		}
		return state.rankList.slice(0, state.rankListPage * 10);
	}
}
