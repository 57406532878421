import helpers from "../../../../helpers";

export default {
	// Fetch matches
	async fetchMatches(ctx, params) {
		let matches = await axios.get('/goalserve/soccer/league/events', { params });

		return matches.data.res;
	},

	// Fetch all existed matches
	async fetchAllMatches({ state, dispatch }, params) {
		let matches = await dispatch('fetchMatches', params.params);

		state.allMatches = matches;

		params.callback();
	},

	// Fetch yesterday matches
	async fetchYesterdayMatches({ state, dispatch }) {
		let matches = await dispatch('fetchMatches', { day_diff: '-1' });

		state.yesterdayMatches = helpers.filterByLeagues(matches);
	},

	// Fetch tomorrow matches
	async fetchTomorrowMatches({ state, dispatch }) {
		let matches = await dispatch('fetchMatches', { day_diff: '1' });

		state.tomorrowMatches = helpers.filterByLeagues(matches);
	},

	// Fetch today matches
	async fetchTodayMatches({ state, dispatch }) {
		let matches = await dispatch('fetchMatches');

		state.todayMatches = helpers.filterByLeagues(matches);
	},

	// Fetch all leagues
	async fetchLeagues({ state }) {
		let leagues = await axios.get('/goalserve/soccer/league');

		state.leagues = leagues.data;
	},
	
	// Fetch leagues data
	async fetchLeaguesData({ commit, state, dispatch }, params) {
		let results = await axios.get('/goalserve/soccer/league/soccerfixtures', { params });

		return results.data.res;
	},
	
	// Fetch all league results
	async fetchLeagueResults({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);
		
		state.leagueResults = results.reverse();
		
		params.callback();
	},
	
	// Fetch all league schedule
	async fetchLeagueSchedule({ commit, state, dispatch }, params) {
		state.leagueSchedule = await dispatch('fetchLeaguesData', params.params);
		
		params.callback();
	},
	
	// Fetch team data
	async fetchTeam({ commit, state, dispatch }, params) {
		let team = await axios.get('/goalserve/soccer/team_info/' + params.params.id);

		state.team = team.data.res;

		params.callback();
	},

	// Fetch all team results
	async fetchTeamResults({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);

		state.teamResults.push(results);

		params.callback();
	},

	// Fetch all team results
	async fetchTeamSchedules({ commit, state, dispatch }, params) {
		let results = await dispatch('fetchLeaguesData', params.params);

		state.teamSchedule.push(results);

		params.callback();
	},
	
	// Fetch all standings
	async fetchStandings({ commit, state, dispatch }, params) {
		let standings = await axios.get('/goalserve/soccer/league/standings', { params: { league_id: params.leagueId } });
		
		state.standings = standings.data.res;
	},
	
	// Fetch all scorers
	async fetchScorers({ commit, state, dispatch }, params) {
		let scorers = await axios.get('/goalserve/soccer/league/topscorers', { params: { league_id: params.leagueId } });
		
		state.scorers = scorers.data.res;
	}
}
