<template>
	<div>
		<header-zet></header-zet>
		
		<div>
			<router-view :key="$route.fullPath"></router-view>
		</div>
		
		<footer-zet></footer-zet>
	</div>
</template>

<script>
	import HeaderZet from "../Layout/Header/HeaderZet";
	import FooterZet from "../Layout/Footer/FooterZet";

	export default {
		components: {
			HeaderZet,
			FooterZet
		}
	}
</script>

<style scoped>

</style>
