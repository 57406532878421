<template>
	<div class="block" v-if="data && data.length">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Новости</h2>
				
				<router-link :to="newsLink" class="header-block_link">Посмотреть все</router-link>
			</div>
			
			
			<div class="last-news">
				<div class="last-news_row" v-for="newsItem in data" :key="newsItem.id">
					<router-link :to="{ name: 'news-item', params: {id: newsItem.id, sport: 'soccer'} }" class="last-news_title">
						{{ newsItem.title }}
					</router-link>
					
					<div class="last-news_date">
						{{ getTime(newsItem.pub_date) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['sport', 'data'],
		
		computed: {
			newsLink() {
				return this.sport ? '/news?page=1&sport=' + this.sport : '/news';
			}
		},
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>
