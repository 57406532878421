import Vue from "vue";

export default (to, from, next) => {
	if (Vue.config.local) {
		//fake(to, from, next); return;
		//next(); return;
	}

	axios.get('/api/profile').then(({data}) => {
		if (to.name === 'home' || to.name === 'landing') {
			window.auth.subscribed = data.subscribed;
			next(); return;
		}
		if (data.msisdn && !data.subscribed) {
			window.auth.auth = true;

			if (to.name === 'subscribe') {
				next(); return;
			} else {
				location.href = '/subscribe';
				return;
			}
		}

		if (data.msisdn && data.subscribed) {
			window.auth.auth = true;

			if (data.subscribed === 2) {
				if (to.name === 'no-money') {
					next(); return;
				} else {
					location.href = '/no-money'; return;
				}
			} else {
				if (to.name === 'subscribe' || to.name === 'no-money') {
					location.href = '/'; return;
				} else {
					window.auth.subscribed = true;
					next(); return;
				}
			}
		} else {
			location.href = data.login_url;
		}
	});
}


function fake(to, from, next) {
	let data = {
		login_url: "https://sso.tele2.ru/auth/realms/tele2-b2c/protocol/openid-connect/auth?client_id=vas-sport&redirect_uri=https://sport.tele2.ru/api/checkauth&response_type=code",
		msisdn: "79013457242",
		subscribed: 0
	}
	
	if (to.name === 'home') {
		window.auth.subscribed = data.subscribed;
		next(); return;
	}
	if (data.msisdn && !data.subscribed) {
		window.auth.auth = true;

		if (to.name === 'subscribe') {
			next(); return;
		} else {
			location.href = '/subscribe';
			return;
		}
	}

	if (data.msisdn && data.subscribed) {
		window.auth.auth = true;

		if (data.subscribed === 2) {
			if (to.name === 'no-money') {
				next(); return;
			} else {
				location.href = '/no-money'; return;
			}
		} else {
			if (to.name === 'subscribe' || to.name === 'no-money') {
				location.href = '/'; return;
			} else {
				window.auth.subscribed = true;
				next(); return;
			}
		}
	} else {
		location.href = data.login_url;
	}
}
