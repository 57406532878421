import Route from "./lib/Route";
import Pages from "./components";

// Home page
Route.set('/').name('home').component(Pages.Home).withProject(window.projects.Default);
//Route.set('/').name('home').component(Pages.HomeFmk).withProject(window.projects.FMK);

// Match center
Route.set('/live/:sport?').name('live').component(Pages.Live).withProject(window.projects.Default);

// League page
Route.set('/league/:sport/:id').name('league').component(Pages.League).withProject(window.projects.Default);

// Tennis rank
Route.set('/rank/tennis/:id').name('tennis-rank').component(Pages.TennisRank).withProject(window.projects.Default);

// Match page - no league
Route.set('/event/:sport/:id').name('event-no-league').component(Pages.Match).withProject(window.projects.Default);

// Match page
Route.set('/event/:sport/:league/:id').name('event').component(Pages.Match).withProject(window.projects.Default);

// Player page
Route.set('/player/:sport/:id').name('player').component(Pages.Player).withProject(window.projects.Default);

// Team page hockey
Route.set('/team/:sport/:league/:id').name('team-hockey').component(Pages.Team).withProject(window.projects.Default);

// Team page
Route.set('/team/:sport/:id').name('team').component(Pages.Team).withProject(window.projects.Default);

// News page
Route.set('/news').name('news').component(Pages.News).withProject(window.projects.Default);

// News item page
Route.set('/news/:sport/:id').name('news-item').component(Pages.NewsItem).withProject(window.projects.Default);

// Articles Page
Route.set('/articles').name('articles').component(Pages.Articles).withProject(window.projects.Default);

// Article Page
Route.set('/articles/:sport/:id').name('article').component(Pages.Article).withProject(window.projects.Default);

// Videos page
Route.set('/videos').name('videos').component(Pages.Videos).withProject(window.projects.Default);

// Video page
Route.set('/videos/:id').name('video').component(Pages.Video).withProject(window.projects.Default);

// Games page
Route.set('/games').name('games').component(Pages.Games).withProject(window.projects.Default);

// Tickets page
Route.set('/tickets').name('tickets').component(Pages.Tickets).withProject(window.projects.Default);

// Subscribe page
Route.set('/subscribe').name('subscribe').component(Pages.Subscribe).withProject(window.projects.Default);
Route.set('/subscribe').name('subscribe').component(Pages.SubscribeTcell).withProject(window.projects.Tcell);
Route.set('/subscribe').name('subscribe').component(Pages.SubscribeTcell).withProject(window.projects.Zet);

// No money page
Route.set('/no-money').name('no-money').component(Pages.NoMoney).middleware('auth').withProject(window.projects.Default);

// 404
Route.set('/404').name('404').component(Pages.NotFound).withProject(window.projects.Default);

// Hockey olympic
Route.set('/olympic').name('olympic').component(Pages.HockeyOlympics).withProject(window.projects.Default);

// Not found
Route.set('*').redirect('/404').withProject(window.projects.Default);


export default Route;
