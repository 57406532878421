<template>
	<div>
		<div class="tab_header">
			<a @click.prevent="change(title)" :href="title" :class="{'router-link-active' : sportValue === title}" v-for="(item, title) in items" :key="title">
				{{ item }}
			</a>
		</div>
	</div>

</template>

<script>
	export default {
		props: ['sport'],

		data() {
			return {
				items: {
					soccer: 'Футбол',
					tennis: 'Теннис',
					hockey: 'Хоккей',
					basketball: 'Баскетбол',
					box: 'Бокс/ММА',
				},
				sportValue: this.sport || ''
			}
		},
		methods: {
			change(value) {
				this.$emit('update', value);
			}
		},
	}
</script>

<style scoped>

</style>
