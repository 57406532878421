export default {
	namespaced: true,

	state: {
		homeVideos: null
	},
	actions: {
		async fetchVideos({ state }, params) {
			let videos = await axios.get('/api/videos', {params: params.params});

			state.homeVideos = videos.data.data;
		},
	},
	getters: {
		homeVideos(state) {
			return state.homeVideos
		}
	}
}
