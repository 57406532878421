<template>
	<div>
		<header-fmk></header-fmk>
		
		<div class="wrapper">
			<div class="content">
				<div class="content_main">
					<router-view :key="$route.fullPath" />
					
					<footer-fmk></footer-fmk>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderFmk from "@/components/Layout/Header/HeaderFmk";
	import FooterFmk from "@/components/Layout/Footer/FooterFmk";
	
	export default {
		components: {
			HeaderFmk,
			FooterFmk
		}
	}
</script>

<style scoped>

</style>
