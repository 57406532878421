<template>
	<ul class="pagination">
		<li class="pagination_prev">
			<a @click.prevent="changePage(current - 1)" :class="{disabled: current === 1}" href="#"></a>
		</li>
		
		<li v-if="!firstPageIsVisible && showFirstPage"><a @click.prevent="changePage(1)" href="">1</a></li>
		
		<li :class="{active: i === current}" v-for="i in pages" :key="i">
			<a @click.prevent="changePage(i)" href=""
			   v-if="i === current">
				{{ i }}
			</a>
			
			<a @click.prevent="changePage(i)" href=""
			   v-if="(i < current) && (i >= current - offset)">
				{{ i }}
			</a>
			
			<a @click.prevent="changePage(i)" href=""
			   v-if="(i > current) && (i <= current + offset)">
				{{ i }}
			</a>
		</li>
		
		<li v-if="!lastPageIsVisible && showLastPage"><a @click.prevent="changePage(lastPage)" href="">{{ lastPage }}</a></li>
		
		<li class="pagination_next">
			<a @click.prevent="changePage(current + 1)" class="page-link" href="#" :class="{disabled: current === lastPage}"></a>
		</li>
	</ul>
</template>

<script>
	import _ from 'underscore';

	export default {
		props: {
			total: Number,
			limit: Number,
			offset: {
				type: Number,
				default: 3
			},
			countBefore: {
				type: Number,
				default: 3
			},
			countAfter: {
				type: Number,
				default: 3
			},
			showFirstPage: {
				default: true,
				type: Boolean
			},
			showLastPage: {
				default: true,
				type: Boolean
			},
		},

		data() {
			return {
				current: Number(this.$route.query.page),
			}
		},

		created() {
			if (typeof this.current !== 'number' || isNaN(this.current)) {
				this.current = 1;
			}
		},


		computed: {
			lastPage() {
				return Math.ceil(this.total / this.limit);
			},
			pages() {
				let arr = [];
				let append = this.current - 1;
				let appended = 0;

				arr.push(this.current);

				while(append > 0 && appended < this.countBefore && append <= this.lastPage) {
					arr.unshift(append);
					append--;
					appended++;
				}

				append = this.current + 1;
				appended = 0;

				while(append > 0 && appended < this.countAfter && append <= this.lastPage) {
					arr.push(append);
					append++;
					appended++;
				}

				return arr;
			},
			lastPageIsVisible() {
				return _.contains(this.pages, this.lastPage)
			},
			lastPrevPageIsVisible() {
				return _.contains(this.pages, this.lastPage - 1)
			},
			firstPageIsVisible() {
				return _.contains(this.pages, 1)
			},
			secondPageIsVisible() {
				return _.contains(this.pages, 2)
			},
		},

		methods: {
			changePage(page) {
				this.current = page;

				this.$emit('page-changed', this.current);
			}
		}
	}
</script>
