<template>
	<div>
		<div class="match">
			<div class="wrapper">
				<div class="match_row">
					<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
					
					<div class="match_right">
						<match-header></match-header>
						
						<tab :headings="{results: 'Результаты', standings: 'Таблица'}" active-key="results">
							<template v-slot:results>
								<links v-if="results" :data="results" :show-date="true"></links>
								<div v-else-if="resultsLoading" class="info-text"></div>
								<div v-else-if="!resultsLoading" class="info">Нет данных</div>
							</template>
							<template v-slot:standings>
								<standings v-if="standings" :data="standings"></standings>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MatchHeader from '@/components/Sports/Common/MatchHeader/MatchHeader';
	import Leagues from "../../../../components/Sports/Common/Leagues/Leagues";
	import Tab from "../../../../components/Tab/Tab";
	import Standings from "../../../../components/Sports/Basketball/Events/Standings/Standings";
	import Links from "../../../../components/Sports/Basketball/Events/Links/Links";

	export default {
		components: {
			MatchHeader,
			Leagues,
			Tab,
			Standings,
			Links
		},

		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				sport: this.$route.params.sport,
				standings: null,
				scorers: null,
				results: null,
				resultsLoading: true,
			}
		},

		created() {
			this.initLeagues();

			this.initStandings();

			this.initResults();
		},


		methods: {
			initLeagues() {
				this.axios.get('/goalserve/' + this.sport + '/leagues')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initStandings() {
				this.axios.get('/goalserve/' + this.sport + '/league/table?league_id=' + this.id)
				.then(({data}) => {
					this.standings = data.res ? data.res : null;
				});
			},
			initResults() {
				this.axios.get('/goalserve/' + this.sport + '/league/fixtures?league_id=' + this.id)
				.then(({data}) => {
					this.results = data.res;
					this.resultsLoading = false;
				})
				.catch(() => {
					this.resultsLoading = false;
				});
			}
		}
	}
</script>
