<template>
	<div>
		<soccer-page v-if="sport === 'soccer'"></soccer-page>
		<hockey-page v-else-if="sport === 'hockey'"></hockey-page>
		<tennis-page v-else-if="sport === 'tennis'"></tennis-page>
		<basketball-page v-else-if="sport === 'bsktbl'"></basketball-page>
		<mma-page v-else-if="sport === 'mma'"></mma-page>
		<box-page v-else-if="sport === 'box'"></box-page>
	</div>
</template>

<script>
	import SoccerPage from "./Sports/Soccer/Soccer";
	import TennisPage from "./Sports/Tennis/Tennis";
	import HockeyPage from "./Sports/Hockey/Hockey";
	import BasketballPage from "./Sports/Basketball/Basketball";
	import MmaPage from "./Sports/Mma/Mma";
	import BoxPage from "./Sports/Box/Box";
	
	export default {
		components: {
			SoccerPage,
			TennisPage,
			HockeyPage,
			BasketballPage,
			MmaPage,
			BoxPage
		},
		
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>
