<template>
	<!-- eslint-disable -->
	
	<div>
		<div class="match_items">
			<div class="match_item">
				<div class="match_item-content" v-for="(scorer, index) in scorers" :key="'scorer' + index">
					<div class="match_item-header active">
						<span v-if="scorer.name" v-text="scorer.name"></span>
						<div v-else>Бомбардиры</div>
					</div>
					<div class="match_item-data active">
						<div class="live-table_row">
							<div class="live-table_col __2">
								<div class="live-table_name">Игрок</div>
							</div>
							<div class="live-table_col __2">
								<div class="live-table_name">Команда</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">Голы</div>
							</div>
						</div>
						<div class="live-table_content">
							<div class="live-table_data __btn">
								<div class="live-table_row __no-color" v-for="player in scorer.player">
									<div class="live-table_col __2">
										<router-link :to="{name: 'player', params: {sport: 'soccer', id: player.id}}" class="live-table_main">
											<div class="live-table_pic">
												<img :src="$hostname + '/goalserve/soccer/player_image/' + player.id" alt="">
											</div>
											<div class="live-table_name-main __tdn" >{{ player.name }}</div>
										</router-link>
									</div>
									<div class="live-table_col __2">
										<div class="live-table_main">
											<router-link :to="{name: 'team', params: {sport: 'soccer', id: player.team_id}}" class="live-table_name-main __tdn">{{ player.team }}</router-link>
										</div>
									</div>
									<div class="live-table_col __3">
										<div class="live-table_name-2">
											{{ player.goals }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],

		created() {

		},

		data() {
			return {
				page: 1,
				leagueId: this.$route.params.id
			}
		},

		computed: {
			scorers() {
				if (Array.isArray(this.data)) {
					return this.data;
				}

				return [
					this.data
				]
			},
		}
	}
</script>

<style scoped>

</style>
