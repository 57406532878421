<template>
	<!-- eslint-disable -->
	
	<div class="match">
		<div class="wrapper">
			<div class="match_row">
				<div class="match_left">
					<leagues v-if="leagues" sport="soccer" :data="leagues"></leagues>
				</div>
				
				<div class="loading" v-if="loadings.teamLoading"></div>
				<div class="match_right" v-else>
					<div class="team">
						<div class="team_main">
							<div class="team_image">
								<img :src="'data:image/png;base64, ' + team.image" alt="">
							</div>
							<div class="team_name">{{ team.name }}</div>
						</div>
						
						<tab :headings="{about: 'О команде', results: 'Результаты', players: 'Состав', schedule: 'Календарь'}" active-key="about">
							<template v-slot:about>
								<div class="command-card_about">
									<table class="command-card_table">
										<tbody>
											<tr>
												<td>Страна</td>
												<td>
													<strong>{{ team.country }}</strong>
												</td>
											</tr>
											<tr>
												<td>Год основания</td>
												<td>
													<strong>{{ team.founded }}</strong>
												</td>
											</tr>
											<tr>
												<td>Место в лиге</td>
												<td>
													<strong>{{ team.leagues.league_rank }}</strong>
												</td>
											</tr>
											<tr>
												<td>Стадион</td>
												<td>
													<strong>{{ team.venue_city["#cdata-section"] }} - {{ team.venue_address["#cdata-section"] }}</strong>
													<br><br>
													<img :src="'data:image/png;base64, ' + team.venue_image" alt="">
												</td>
											</tr>
											<tr>
												<td>Вмещаемость стадиона</td>
												<td>
													<strong>{{ team.venue_capacity }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
							
							<template v-slot:players>
								<players :team="team" :players="players"></players>
							</template>
							
							<template v-slot:results>
								<div class="loading" v-if="loadings.resultsLoading"></div>
								<links v-else :sport="sport" :data="results"></links>
								<div v-else class="info">Нет данных</div>
							</template>
							
							<template v-slot:schedule>
								<div class="loading" v-if="loadings.scheduleLoading"></div>
								<links :show-date="true" v-else :sport="sport" :data="schedules"></links>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'underscore';
	import { mapActions, mapGetters } from 'vuex'

	import Leagues from "@/components/Sports/Common/Leagues/Leagues";
	import Tab from "@/components/Tab/Tab";
	import Players from "@/components/Sports/Soccer/Team/Players/Players";
	import Links from "@/components/Sports/Soccer/Events/Links/Links";

	export default {
		components: {
			Leagues,
			Tab,
			Players,
			Links,
		},

		data() {
			return {
				id: this.$route.params.id,
				leagueId: this.$route.params.id,
				sport: this.$route.params.sport,
				loading: true,
				loadings: {
					resultsLoading: true,
					scheduleLoading: true,
					teamLoading: true,
				}
			}
		},

		computed: {
			...mapGetters({
				leagues: 'soccer/leagues',
				results: 'soccer/teamResults',
				schedules: 'soccer/teamSchedule',
				team: 'soccer/team',
			}),
			
			players() {
				if (!this.team.squad.player) {
					return null
				}
				return _.groupBy(this.team.squad.player, (item) => {
					return item.position;
				});
			},
			transfers() {
				return this.team.transfers || null;
			},
		},

		created() {
			this.fetchLeagues();
			
			this.fetchTeam({
				params: {
					id: this.id,
				},
				callback: () => {
					this.loadings.teamLoading = false;
					
					if (this.team.leagues.league_id) {
						this.team.leagues.league_id.forEach((league) => {
							this.fetchTeamResults({
								params: {league_id: league, team_id: this.id, status: 'finished'},
								callback: () => {
									this.loadings.resultsLoading = false;
								}
							});

							this.fetchTeamSchedules({
								params: {league_id: league, team_id: this.id, status: 'not_finished'},
								callback: () => {
									this.loadings.scheduleLoading = false;
								}
							});
						});
					}
				}
			});
		},

		methods: {
			...mapActions({
				fetchLeagues: 'soccer/fetchLeagues',
				fetchTeamResults: 'soccer/fetchTeamResults',
				fetchTeamSchedules: 'soccer/fetchTeamSchedules',
				fetchTeam: 'soccer/fetchTeam',
			}),
		}
	}
</script>
