export default {
	yesterdayMatches: null,
	tomorrowMatches: null,
	todayMatches: null,
	liveMatches: null,
	allMatches: null,

	leagues: null,
	
	rankList: null,
	rankListPage: 1
}
