<template>
	<div>
		<div class="match">
			<div class="wrapper">
				<div class="match_row">
					<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
					
					<div class="match_right">
						<match-header></match-header>
						
						<tab :headings="{results: 'Результаты', schedule: 'Расписание', standings: 'Таблица', scorers: 'Бомбардиры'}" active-key="results">
							<template v-slot:results>
								<div v-if="loadings.resultsLoading" class="loading"></div>
								<links v-else :data="leagueResults"></links>
							</template>
							<template v-slot:schedule>
								<div v-if="loadings.scheduleLoading" class="loading"></div>
								<links v-else :data="leagueSchedule"></links>
							</template>
							<template v-slot:standings>
								<standings v-if="standings" :data="standings"></standings>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:scorers>
								<scorers v-if="scorers" :data="scorers"></scorers>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";
	
	import Leagues from "@/components/Sports/Common/Leagues/Leagues"
	import MatchHeader from "@/components/Sports/Common/MatchHeader/MatchHeader"
	import Tab from "@/components/Tab/Tab";
	import Links from "@/components/Sports/Soccer/Events/Links/Links";
	import Standings from "@/components/Sports/Soccer/Events/Standings/Standings";
	import Scorers from "@/components/Sports/Soccer/Events/Scorers/Scorers";

	export default {
		components: {
			MatchHeader,
			Leagues,
			Tab,
			Standings,
			Scorers,
			Links
		},

		data() {
			return {
				leagueId: this.$route.params.id,
				sport: this.$route.params.sport,
				loadings: {
					resultsLoading: true,
					scheduleLoading: true
				}
			}
		},

		computed: {
			...mapGetters({
				leagues: 'soccer/leagues',
				leagueResults: 'soccer/leagueResults',
				leagueSchedule: 'soccer/leagueSchedule',
				standings: 'soccer/standings',
				scorers: 'soccer/scorers',
			}),
		},

		created() {
			this.fetchLeagues();
			this.fetchLeagueResults({
				params: {
					league_id: this.leagueId,
					status: 'finished'
				},
				callback: () => {
					this.loadings.resultsLoading = false;
				}
			});
			this.fetchLeagueSchedule({
				params: {
					league_id: this.leagueId,
					status: 'not_finished'
				},
				callback: () => {
					this.loadings.scheduleLoading = false;
				}
			});
			this.fetchStandings({ leagueId: this.leagueId });
			
			this.fetchScorers({ leagueId: this.leagueId });
		},


		methods: {
			...mapActions({
				fetchLeagues: 'soccer/fetchLeagues',
				fetchLeagueResults: 'soccer/fetchLeagueResults',
				fetchLeagueSchedule: 'soccer/fetchLeagueSchedule',
				fetchStandings: 'soccer/fetchStandings',
				fetchScorers: 'soccer/fetchScorers',
			})
		}
	}
</script>
