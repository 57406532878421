export default {
	async fetchMatchEvent({ state }, params) {
		let requestParams = {league_id: params.league_id, day_diff: params.dayDiff, static_id: params.static_id}
		
		let matchEvent = await axios.get('/goalserve/soccer/league/events', {params: requestParams})

		state.matchEvent = matchEvent.data.res ? matchEvent.data.res[0].events[0] : null;
		
		if (params.callback) {
			params.callback();
		}
	},
	
	async fetchMatch({ state }, params) {
		let requestParams = {league_id: params.league_id, day_diff: params.dayDiff, static_id: params.static_id}
		
		let match = await axios.get('/goalserve/soccer/league/event/comments', {params: requestParams});
		
		state.match = match.data.res ? match.data.res.tournament.match : null;

		if (params.callback) {
			params.callback();
		}
	},
	async fetchH2h({ state }, params) {
		let h2h = await axios.get('/goalserve/soccer/h2h?team1=' + params.home_id + '&team2=' + params.away_id);
		
		state.h2h = h2h.data.res;
	}
}
