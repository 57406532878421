<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<div>
					<div class="match_item-header active">
						<span v-text="group.league_name"></span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in group.events"
										 class="match-data_item" 
										 :to="getLink(match)">
								<link-hockey :match="match" :show-date="showDate"></link-hockey>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LinkHockey from "@/components/Sports/Hockey/Events/Link/Link";
	
	export default {
		components: {
			LinkHockey
		},
		
		props: ['data', 'showDate', 'dayDiff'],
		
		computed: {
			matches() {
				return this.data;
			},
		},
		
		methods: {
			getLink(match) {
				return { 
					name: 'event',
					params: {
						id: match.static_id,
						league: match.league_id,
						sport: 'hockey'
					},
					query: {
						day_diff: this.dayDiff ? this.dayDiff : 0
					}
				}
			}
		}
	}
</script>

<style scoped>

</style>
