export default {
	namespaced: true,

	state: {
		player: null
	},
	actions: {
		async fetchPlayer({ state }, params) {
			let player = await axios.get('/goalserve/soccer/player/' + params.id);
			
			state.player = player.data.res;
		}
	},
	getters: {
		player(state) {
			return state.player;
		}
	}
}
