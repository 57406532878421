<template>
	<div class="block">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Популярное</h2>
			</div>
			
			<div data-sportrecs="Soj65mcbygYUcmVE4cF0SD1nc3lEmaX1W-f0xhx6JDE"></div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		created() {
			var s = document.createElement("script");
			s.type = "text/javascript";
			s.src = "https://sportrecs.com/showcase/Soj65mcbygYUcmVE4cF0SD1nc3lEmaX1W-f0xhx6JDE/widget.js";
			$("body").append(s);
		}
	}
</script>

<style scoped>

</style>
