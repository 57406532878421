<template>
	<div class="match-text">
		<div class="match-text_item" v-if="isFinished">
			<div class="match-text_info">
				<div class="match-text_info-time">90'</div>
				<div class="timeline_whistle"></div>
			</div>
			<div class="match-text_data">
				{{ getRandomMessage('finish') }}
			</div>
		</div>
		<div v-for="(incident, index) in summary" :key="index">
			<div v-if="incident.player && incident.player.search('(pen.)') !== -1">
				<div class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_ball"></div>
					</div>
					
					<div class="match-text_data __goal">{{ getRandomMessage('penalty', incident) }}</div>
				</div>
				<div class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_warning"></div>
					</div>
					
					<div class="match-text_data">{{ getRandomMessage('beforePenalty') }}</div>
				</div>
			</div>
			<div v-else-if="incident.type === 'goals' || incident.type === 'goal'" class="match-text_item">
				<div class="match-text_info">
					<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
					<div class="timeline_ball"></div>
				</div>
				
				<div class="match-text_data __goal" v-if="incident.player.search('(o.g.)') !== -1">{{ getRandomMessage('autogoal', incident) }}</div>
				<div class="match-text_data __goal" v-else>{{ getRandomMessage('goal', incident) }}</div>
			</div>
			<div v-else-if="incident.substitution || incident.type === 'subst'" class="match-text_item">
				<div class="match-text_info">
					<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
					<div class="timeline_arrow __subst"></div>
				</div>
				
				<div class="match-text_data">{{ getRandomMessage('replace', incident) }}</div>
			</div>
			<div v-else-if="incident.type === 'yellowcards' || incident.type === 'yellowcard'" class="match-text_item">
				<div class="match-text_info">
					<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
					<div class="timeline_cart __yellow"></div>
				</div>
				
				<div class="match-text_data">{{ getRandomMessage('yellow', incident) }}</div>
			</div>
		</div>
		<div class="match-text_item" v-if="isStarted">
			<div class="match-text_info">
				<div class="match-text_info-time">1'</div>
				<div class="timeline_whistle"></div>
			</div>
			
			<div class="match-text_data">{{ getRandomMessage('start') }}</div>
		</div>
		<div class="match-text_item">
			<div class="match-text_info">
				<div class="timeline_shirt"></div>
			</div>
			
			<div class="match-text_data">C составами и расстановками команд Вы можете ознакомиться на вкладке Составы.</div>
		</div>
		<div class="match-text_item">
			<div class="match-text_info">
				<div class="timeline_shirt"></div>
			</div>
			
			<div class="match-text_data">Матч совсем скоро начнется.</div>
		</div>
		<div class="match-text_item">
			<div class="match-text_info">
				<div class="timeline_shirt"></div>
			</div>
			
			<div class="match-text_data">Добрый вечер, уважаемые болельщики! Добро пожаловать на нашу трансляцию.</div>
		</div>
	</div>
</template>

<script>
	import _ from "underscore";

	export default {
		props: ['match', 'matchEvent'],

		data() {
			return {
				id: this.$route.params.id,
				messages: {
					start: [
						'Первый тайм начинается.',
						'Сегодняшний матч только что начался.',
						'Первая половина встречи началась.',
						'Игра только что началась.'
					],
					goal: [
						'ГОЛ! [name] наносит отличный удар головой после не менее отличного навеса. Ни одного шанса для вратаря спасти команду.',
						'ГОЛ! [name] оказался в нужном месте в нужное время и, получив мяч на подборе в штрафной, пробил низом в самый угол ворот.',
						'Мяч в сетке! [name] наносит голевой удар в правый угол ворот.',
						'ГОЛ! [name] забивает точным ударом с угла штрафной в левый угол ворот.',
						'ГОЛ! Отличный пас в штрафную, мяч получает [name] и на 100% использует свой момент нанося точный удар в левый угол ворот.',
						'ГОЛ! [name] нанес удар, получился рикошет от кого-то в штрафной и мяч в сетке, вратарь был бессилен.',
						'ГОЛ! [name] классно завершает на втором этаже ударом под перекладину, вратарь оказался переигран. Отличный удар получился, браво!',
						'Это ГОЛ! После классного паса [name] с близкого расстояния вколачивает мяч в сетку!',
						'ГОЛ! [name] шикарно открылся в штрафной и нанес прицельный удар над вратарем в левый нижний угол.',
						'ГОЛ! [name] пробил из пределов штрафной и переиграл голкипера ударом в нижний угол ворот.'
					],
					autogoal: [
						'[name] пока главный неудачник матча, так как в свои ворота он только что забил гол.',
						'АВТОГОЛ! [name] переправил мяч в собственные ворота. Не повезло.',
						'Автогол. [name] пытался выбить мяч из штрафной, но случайно отправил его в правый угол собственных. Вратарь ничего не мог поделать.',
					],
					beforePenalty: [
						'Игрок должен был знать, что так вести себя на поле нельзя. Это слишком грубо, и судья свистит, сигнализируя, что было нарушение. Это одиннадцатиметровый. Шанс забить для команды',
						'Судя по свистку, который дал арбитр. Команда вознаграждена одиннадцатиметровым. Один из игроков будет бить. Посмотрим, что будет.',
						'Это одиннадцатиметровый! Шанс забить для команды!',
						'Арбитр все видел и дал свисток. Переговорив с помощником, арбитр назначил пенальти. Хороший шанс забить.',
						'Арбитр свистит фол и принял решение. Это пенальти!'
					],
					penalty: [
						'ГОЛ! Голкипер среагировал на удар с пенальти в исполнении [name], но не смог отбить мяч, который залетел в правый нижний угол.',
						'ГОЛ! [name] выиграл битву характеров и уверенно переиграл вратаря, мощно пробив пенальти в левую часть ворот.',
						'ГОЛ! [name] отправил мяч под левую штангу мимо вытянутой руки вратаря.',
						'ГОЛ! [name] выиграл битву характеров и уверенно переиграл вратаря, мощно пробив пенальти в правый нижний угол.',
					],
					replace: [
						'[on] заменен, а на его место вступил [off].',
						'[on] выходит на поле. [off] уйдет, совсем медленно он передвигается по полю в последние минуты.',
						'Вот и замена. [off] покинет игру, а свеженький [on] заступит вместо него.',
						'А вот и замена. [off] сейчас уйдет с поля. Тренер дает последние наставления своему игроку, это [on] выходит на поле.',
						'[on] присоединяется к команде на поле, выйдя на замену. [off] заменен. Посмотрим, что он привнесет в игру.',
						'Время для замены. [off] покидает поле. [on] должен усилить игру.',
						'Время для замены. [off] заменен. [on] освежит игру.',
						'Замена будет сейчас произведена. [off] покинет поле, а вместо него выйдет [on].',
					],
					yellow: [
						'[name] заработал горчичник.',
						'[name] вымотал все нервы судье, и он берется наказать игрока.',
						'[name] получил предупреждение за очень грубую игру. Пусть радуется, что не красная.',
						'Заслуженный горчичник. Подкат был жесткий, и судья наказал заслуженно, [name] с предупреждением теперь.',
						'[name] получает карточку, судья матча наказал его желтой.',
						'Желтая! [name] наказан за свой фол.',
						'[name] награжден желтой карточкой за жесткую борьбу. Он, кажется, не согласен, но судья не принимает жалоб.',
						'Судья показывает желтую карточку, это [name] наказан за свое нарушение.',
					],
					finish: [
						'Игра закончена, арбитр дал свисток к концу матча.',
						'Вот и все на сегодня. Игра окончена.',
						'Арбитр поглядел на часы и решил закончить матч.'
					]
				}
			}
		},

		computed: {
			summary() {
				return this.getSummary().reverse();
			},
			isStarted() {
				if (this.matchEvent) {
					return isNaN(parseInt(this.matchEvent.status));
				}

				return true;
			},
			isFinished() {
				if (this.matchEvent) {
					return this.matchEvent.status === 'FT' || this.matchEvent.status === 'Finished';
				}

				return true;
			}
		},

		methods: {
			getRandomMessage(type, incident = null) {
				let localData = this.getLocalData();

				if (localData[this.id] && localData[this.id][this.getTypeName(type, incident)]) {
					if (incident) {
						return localData[this.id][this.getTypeName(type, incident)];
					} else {
						return localData[this.id][type];
					}
				} else if (!localData[this.id]) {
					localData[this.id] = {};
				}

				let index = this.getRandomIndex(this.messages[type]);

				let message = this.messages[type][index];

				let name = '';
				let on = '';
				let off = '';

				if (incident) {
					if (incident.substitution) {
						on = incident.on;
						off = incident.off;
					} else if (incident.type === 'subst') {
						on = incident.player;
						off = incident.assist;
					} else {
						if (incident.name) {
							name = incident.name;
						} else if (incident.player) {
							name = incident.player.replace('(pen.)', '');
							name = incident.player.replace('(o.g.)', '');
						}
					}
				}

				message = message.replace('[name]', name);
				message = message.replace('[off]', off);
				message = message.replace('[on]', on);

				if (incident) {
					localData[this.id][this.getTypeName(type, incident)] = message;
				} else {
					localData[this.id][type] = message;
				}

				localStorage.setItem('messages', JSON.stringify(localData));

				return message;
			},
			getRandomIndex(data) {
				return Math.floor(Math.random() * data.length);
			},
			getSummary() {
				if (this.matchEvent) {
					return this.matchEvent.game_events;
				}

				let summary = this.match.summary.concat(this.initSubstitutions());

				summary = _.sortBy(summary, function (item) {
					return Number(item.minute);
				});

				return summary;
			},
			initSubstitutions() {
				let arr = [];

				let data = null;

				if (this.match.substitutions.localteam) {
					data = this.match.substitutions.localteam.substitution;

					if (!Array.isArray(this.match.substitutions.localteam.substitution)) {
						data = [data];
					}

					data.forEach((item) => {
						item.team = 'localteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}

				if (this.match.substitutions.visitorteam) {
					data = this.match.substitutions.visitorteam.substitution;

					if (!Array.isArray(this.match.substitutions.visitorteam.substitution)) {
						data = [data];
					}

					data.forEach((item) => {
						item.team = 'visitorteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}

				return arr;
			},
			getLocalData() {
				let data = JSON.parse(localStorage.getItem('messages'));

				if (!data) {
					data = {};
					localStorage.setItem('messages', JSON.stringify(data))
				}

				return data;
			},
			getTypeName(type, incident) {
				if (incident) {
					let id = incident.eventid ? incident.eventid : incident.id;

					if (incident.on_id) {
						id = incident.on_id + '-' + incident.off_id;
					}

					return type + '-' + id;
				}
				return type;
			}
		}
	}
</script>

<style scoped lang="less">
	.match-text_item {
		border-bottom: 1px solid #ccc;
		display: flex;
		align-items: center;
	}
	.match-text_info {
		width: 80px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.match-text_data {
		font-size: 14px;
		border-left: 1px solid #ccc;
		padding: 8px 0 8px 15px;
		
		&.__goal {
			color: #c00;
			font-weight: 500;
		}
	}
	.match-text_info-time {
		font-size: 14px;
		margin-right: 10px;
		width: 25px;
		flex-shrink: 0;
		text-align: center;
	}
	.timeline_ball, .timeline_arrow {
		width: 20px;
		height: 20px;
		margin: 0;
	}
	.timeline_cart {
		width: 20px;
		height: 25px;
		margin: 0;
	}
	.timeline_whistle {
		background: #fff url('/img/whistle.png') no-repeat;
		width: 20px;
		height: 15px;
		background-size: contain;
	}
	.timeline_shirt {
		background: #fff url('/img/dress-blue.gif') no-repeat;
		width: 25px;
		height: 22px;
		background-size: contain;
	}
	.timeline_warning {
		width: 20px;
		height: 20px;
		margin: 0;
	}
</style>
