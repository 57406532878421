export default {
	yesterdayMatches: null,
	tomorrowMatches: null,
	todayMatches: null,
	liveMatches: null,
	allMatches: null,

	leagues: null,
	leagueResults: null,
	leagueResults2: null,
	leagueSchedule: null,
	leagueSchedule2: null,

	team: null,
	teamResults: [],
	teamSchedule: [],
	
	standings: null,
	scorers: null,
}
