<template>
	<div class="match">
		<div class="wrapper">
			<div class="match_heading">События</div>
			
			<div class="match_row">
				<categories :data="categories"></categories>
				
				<div class="match_right">
					<div class="tab">
						<match-header></match-header>
						
						<tab :headings="{active : 'Последние'}" active-key="active" :base="true">
							<template v-slot:active>
								<list v-if="matches" :data="matches"></list>
								<div v-else-if="matchesLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MatchHeader from "@/components/Sports/Common/MatchHeader/MatchHeader"
	import Tab from "@/components/Tab/Tab";
	import Categories from "@/components/Sports/Box/Categories/Categories";
	import List from "@/components/Sports/Box/List/List";

	export default {
		components: {
			MatchHeader,
			Categories,
			Tab,
			List
		},

		data() {
			return {
				categories: null,
				matches: null,
				matchesLoading: true
			}
		},

		created() {
			this.axios.get('/goalserve/box')
			.then(({data}) => {
				this.matches = data.res;
				this.matchesLoading = false;
			})
			.catch(() => {
				this.matchesLoading = false;
			})
			this.axios.get('/goalserve/box/weights')
			.then(({data}) => {
				this.categories = data.res;
			});
		},
	}
</script>

<style scoped>

</style>
