import actions from "./modules/actions";
import state from "./modules/state";
import getters from "./modules/getters";

export default {
	namespaced: true,
	state: state,
	actions: actions,
	getters: getters
}
