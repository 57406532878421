<template>
	<div>
		<soccer-team v-if="sport === 'soccer'"></soccer-team>
		<hockey-team v-else-if="sport === 'hockey'"></hockey-team>
		<basketball-team v-else-if="sport === 'bsktbl'"></basketball-team>
	</div>
</template>

<script>
	import SoccerTeam from "./Sports/Soccer/Soccer";
	import HockeyTeam from "./Sports/Hockey/Hockey";
	import BasketballTeam from "./Sports/Basketball/Basketball";

	export default {
		components: {
			SoccerTeam,
			HockeyTeam,
			BasketballTeam,
		},

		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>
