<template>
	<div>
		<soccer-match-page v-if="sport === 'soccer'"></soccer-match-page>
		<hockey-match-page v-else-if="sport === 'hockey'"></hockey-match-page>
		<tennis-match-page v-else-if="sport === 'tennis'"></tennis-match-page>
		<basketball-match-page v-else-if="sport === 'bsktbl'"></basketball-match-page>
		<mma-match-page v-else-if="sport === 'mma'"></mma-match-page>
	</div>
</template>

<script>
	import SoccerMatchPage from "./Sports/Soccer/Soccer";
	import TennisMatchPage from "./Sports/Tennis/Tennis";
	import HockeyMatchPage from "./Sports/Hockey/Hockey";
	import BasketballMatchPage from "./Sports/Basketball/Basketball";
	import MmaMatchPage from "./Sports/Mma/Mma";

	export default {
		components: {
			SoccerMatchPage,
			TennisMatchPage,
			HockeyMatchPage,
			BasketballMatchPage,
			MmaMatchPage
		},
		data() {
			return {
				sport: this.$route.params.sport,
			}
		}
	}
</script>
