<template>
	<div>
		<banner type="main"></banner>
		
		<!--<div class="olympic __p0">
			<div class="wrapper">
				<router-link :to="{name: 'olympic'}" class="olympic_banner">
					<img src="/img/olympic.png" alt="">
				</router-link>
			</div>
		</div>-->
		
<!--		<home-matches></home-matches>-->
		
<!--		<popular-videos></popular-videos>-->
		
		<articles  v-if="articles" sport="soccer" :data="articles"></articles>
		
		<news v-if="news" sport="soccer" :data="news"></news>
		
		<videos sport="soccer" :data="videos"></videos>
		
		<games :data="games"></games>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	
	import Banner from "@/components/Banner/Banner";
	import HomeMatches from "@/components/Home/Matches/Matches";
	import PopularVideos from "@/components/Home/PopularVideos/PopularVideos";
	import News from "@/components/News/News";
	import Articles from "@/components/Articles/Articles";
	import Videos from "@/components/Videos/Videos";
	import Games from "@/components/Games/Games";
	
	export default {
		components: {
			Articles,
			Banner,
			HomeMatches,
			PopularVideos,
			News,
			Videos,
			Games
		},
		data() {
			return {
				
			}
		},
		
		computed: {
			...mapGetters({
				news: 'news/news',
				articles: 'articles/homeArticles',
				videos: 'videos/homeVideos',
				games: 'games/games',
			})
		},
		
		created() {
			this.fetchNews({
				params: {sport: 'soccer', limit: 3, page: 1}
			});
			this.fetchArticles({
				params: {sport: 'soccer', limit: 3, page: 1}
			});
			this.fetchVideos({
				params: {limit: 5, sport: 'soccer'}
			});
			this.fetchGames();
		},
		
		methods: {
			...mapActions({
				fetchNews: 'news/fetchNews',
				fetchArticles: 'articles/fetchArticles',
				fetchVideos: 'videos/fetchVideos',
				fetchGames: 'games/fetchGames',
			})
		}
	}
</script>

<style scoped>

</style>
