export default {
	namespaced: true,

	state: {
		homeArticles: null
	},
	actions: {
		async fetchArticles({ state }, params) {
			let articles = await axios.get('/api/articles', {params: params.params});

			state.homeArticles = articles.data.data;
		},
	},
	getters: {
		homeArticles(state) {
			return state.homeArticles
		}
	}
}
