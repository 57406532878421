<template>
	<div>
		<div class="olympic">
			<div class="wrapper">
				<div class="olympic_title">
					<img src="/img/olympic.png" alt="">
				</div>
				
				<div class="olympic_row">
					<div class="olympic_col __1">
						<div class="olympic_news">
							<olympics-news :data="news"></olympics-news>
							
							<button @click="goToNews" class="olympic_button">Все новости</button>
						</div>
					</div>
					<div class="olympic_col __2">
						<div class="olympic_heading">Олимпийские игры</div>
						<tab :headings="{results: 'Результаты', schedule: 'Расписание'}" active-key="results">
							<template v-slot:results>
								<div class="loading" v-if="resultsLoading"></div>
								<results :data="results"></results>
							</template>
							<template v-slot:schedule>
								<schedule v-if="schedule" :data="schedule"></schedule>
							</template>
						</tab>
						
						<br><br><br>
						
						<div class="olympic_medal" v-if="medals">
							<table class="olympic_medal-table">
								<thead>
									<tr>
										<td>
											<div class="olympic_medal-title">Медальный зачёт</div>
										</td>
										
										<td>
											<div class="olympic_medal-title-2">
												<img src="/img/m1.png" alt="">
												<span>Золото</span>
											</div>
										</td>
										<td>
											<div class="olympic_medal-title-2">
												<img src="/img/m2.png" alt="">
												<span>Серебро</span>
											</div>
										</td>
										<td>
											<div class="olympic_medal-title-2">
												<img src="/img/m3.png" alt="">
												<span>Бронза</span>
											</div>
										</td>
										<td>
											<div class="olympic_medal-title-2">
												<img src="/img/m4.png" alt="">
												<span>Всего</span>
											</div>
										</td>
									</tr>
								</thead>
								<tbody>
									<tr :class="{'__show-all': showAllData}" v-for="medal in medals" :key="medal.td1">
										<td>
											<div class="olympic_medal-data">
												<div class="olympic_medal-num">{{ medal.td1 }}</div>
												<div class="olympic_medal-flag">
													<img :src="medal.td2" alt="">
												</div>
												<div class="olympic_medal-name">{{ medal.td3}}</div>
											</div>
										</td>
										<td>
											<div class="olympic_medal-value">{{ medal.td4}}</div>
										</td>
										<td>
											<div class="olympic_medal-value">{{ medal.td5}}</div>
										</td>
										<td>
											<div class="olympic_medal-value">{{ medal.td6}}</div>
										</td>
										<td>
											<div class="olympic_medal-value">{{ medal.td7}}</div>
										</td>
									</tr>
								</tbody>
							</table>
							
							<button v-if="!showAllData" @click="showAll" class="olympic_button">Показать все</button>
						</div>
						
						
						<div class="olympic_news __mobile">
							<olympics-news :data="news"></olympics-news>
							
							<button @click="goToNews" class="olympic_button">Все новости</button>
						</div>
					</div>
				</div>
			</div>
			
			<div class="olympic_articles">
				<div class="wrapper">
					<olympic-articles :data="articles"></olympic-articles>
					
					<button @click="goToArticles" class="olympic_button">Все статьи</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";
	import $ from 'jquery';
	
	import OlympicsNews from "@/components/News/OlympicsNews";
	import OlympicArticles from "@/components/Articles/OlympicArticles";
	import Tab from "@/components/Tab/Tab";
	import Leagues from "@/components/Sports/Common/Leagues/Leagues";
	import Standings from "@/components/Sports/Hockey/Events/Standings/Standings";
	import Results from "@/components/Sports/Hockey/Olympic/Results";
	import Schedule from "@/components/Sports/Hockey/Olympic/Schedule";
	
	export default {
		components: {
			OlympicArticles,
			OlympicsNews,
			Leagues,
			Tab,
			Standings,
			Results,
			Schedule
		},

		data() {
			return {
				leagueId: 2048,
				leagueId2: 2041,
				resultsLoading: true,
				resultsLoading2: true,
				medalsHtml: null,
				showAllData: false,
				
				results: null,
				schedule: null
			}
		},
		
		computed: {
			...mapGetters({
				news: 'news/news',
				articles: 'articles/homeArticles',
				leagueResults: 'hockey/leagueResults',
				leagueResults2: 'hockey/leagueResults2',
				leagueSchedule: 'hockey/leagueSchedule',
				leagueSchedule2: 'hockey/leagueSchedule2',
				standings: 'hockey/standings',
			}),
			medals() {
				if (!this.medalsHtml) {
					return null;
				}

				let data = this.medalsHtml.replace('<!DOCTYPE HTML>', '');

				let el = document.createElement( 'html' );
				el.innerHTML = data;

				data = $(el).find('.se-olympic-medals-short__table tbody tr');
				
				let arr = [];
				
				data.each((key, value) => {
					let td1 = $(value).find('td:nth-child(1)').html();
					let td2 = $(value).find('td:nth-child(2) img').attr('src').replace('https://ss.sport-express.ru', '');
					let td3 = $(value).find('td:nth-child(3) .se-olympic-medals-short__country').html();
					let td4 = $(value).find('td:nth-child(4)').html();
					let td5 = $(value).find('td:nth-child(5)').html();
					let td6 = $(value).find('td:nth-child(6)').html();
					let td7 = $(value).find('td:nth-child(7)').html();
					
					arr.push({
						td1, td2, td3, td4, td5, td6, td7
					});
				});
				
				//console.log(arr);
				
				return arr;
			}
		},

		created() {
			this.fetchNews({
				params: {sport: 'hockey', limit: 15, page: 1, tag: 'Олимпийские игры'}
			});
			this.fetchArticles({
				params: {sport: 'hockey', limit: 5, page: 1, tag: 'Олимпийские игры'}
			});
			
			
			this.axios.get('/goalserve/olympic_results')
				.then(({data}) => {
					this.results = data.results.date;
				})

			this.axios.get('/goalserve/olympic_schedules')
				.then(({data}) => {
					this.schedule = data.schedules.date;
				})

			this.fetchLeagueResults({
				params: {
					league_id: this.leagueId,
					status: 'finished'
				},
				callback: () => {
					this.resultsLoading = false;
				}
			});

			this.fetchLeagueResults2({
				params: {
					league_id: this.leagueId2,
					status: 'finished'
				},
				callback: () => {
					this.resultsLoading2 = false;
				}
			});
			
			this.fetchLeagueSchedule({
				params: {
					league_id: this.leagueId,
					status: 'not_finished'
				},
			});
			this.fetchLeagueSchedule2({
				params: {
					league_id: this.leagueId2,
					status: 'not_finished'
				},
			});
			
			this.axios.get('/api/medals').then(({data}) => {
				this.medalsHtml = data;
			})
		},

		methods: {
			...mapActions({
				fetchNews: 'news/fetchNews',
				fetchArticles: 'articles/fetchArticles',

				fetchLeagueResults: 'hockey/fetchLeagueResults',
				fetchLeagueResults2: 'hockey/fetchLeagueResults2',
				fetchLeagueSchedule: 'hockey/fetchLeagueSchedule',
				fetchLeagueSchedule2: 'hockey/fetchLeagueSchedule2',
				fetchStandings: 'hockey/fetchStandings',
			}),
			goToNews() {
				this.$router.push({
					name: 'news',
					query: {
						sport: 'hockey',
						tag: 'Олимпийские игры'
					}
				})
			},
			goToArticles() {
				this.$router.push({
					name: 'articles',
					query: {
						sport: 'hockey',
						tag: 'Олимпийские игры'
					}
				})
			},
			showAll() {
				this.showAllData = true;
			}
		}
	}
</script>

<style scoped>

</style>
