<template>
	<div class="match-data_row">
		<div class="match-data_col-1" v-if="match.status === 'Not Started'" v-text="match.date"></div>
		<div class="match-data_col-1" v-else v-text="getStatus(match.status)"></div>
		
		<div class="match-data_col-2" :class="{__bold: bold(match.localteam.totalscore, match.awayteam.totalscore)}">
			<img :src="$hostname + '/goalserve/hockey/team_image/' + match.localteam.id" alt="">
			<span v-text="match.localteam.name"></span>
		</div>
		<div class="match-data_col-3">
			{{ match.localteam.totalscore !== '?' ? match.localteam.totalscore: '' }}
		</div>
		<div class="match-data_col-4">-</div>
		<div class="match-data_col-5">
			{{ match.awayteam.totalscore !== '?' ? match.awayteam.totalscore: '' }}
		</div>
		<div class="match-data_col-6" :class="{__bold: bold(match.awayteam.totalscore, match.localteam.totalscore)}">
			<span v-text="match.awayteam.name"></span>
			<img :src="$hostname + '/goalserve/hockey/team_image/' + match.awayteam.id" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		props: ['match', 'showDate'],
		
		methods: {
			bold(team1, team2) {
				if (isNaN(parseInt(team1)) || isNaN(parseInt(team2)))
					return false;
				
				return parseInt(team1) > parseInt(team2);
			},
			getStatus(status) {
				let data = {
					'1st Period': '1-й период',
					'2nd Period': '2-й период',
					'3rd Period': '3-й период',
					'Finished': 'Завершен',
					'Break Time': 'Перерыв',
					'Cancelled': 'Отменен',
					'After Penalties': 'После буллитов',
					'After Over Time': 'После ОТ',
					'Postponed': 'Перенесен',
					'Awarded': 'Тех. Поражение'
				};
				
				return data[status] ? data[status] : status;
			}
		}
	}
</script>

<style scoped>

</style>
