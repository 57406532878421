import { render, staticRenderFns } from "./TotalResults.vue?vue&type=template&id=7b9337cf&scoped=true&"
import script from "./TotalResults.vue?vue&type=script&lang=js&"
export * from "./TotalResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b9337cf",
  null
  
)

export default component.exports